@import '../../../dx-styles.scss';
@import '../../../utils/custom-additional-variables.scss';

.Event_User_PictureSection {

    #dropzone-external {
        width: 152px;
        height: 152px;
        border-radius: 4px;
        background-color: $base-picture-bg;
        border-width: 1px;
        border-style: solid;
        padding: 0px;
        display: flex;
        justify-content: center;
    }

    #dropzone-external>* {
        pointer-events: none;
    }

    #dropzone-external.dropzone-active {
        border-style: solid;
    }

    #dropzone-image {
        max-width: 100%;
        max-height: 100%;
    }

    .Image-Preview-Container {
        display: flex;
        vertical-align: middle !important;
        align-items: center;
        justify-content: center;
        max-height: 100%;
        max-width: 100%;
    }
    
    .Login-banner-Preview {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
    }

    #dropzone-text {
        @extend .font-regular-13;


        span {
            font-weight: 100;
            //opacity: 0.5;
        }
    }

    #upload-progress {
        display: flex;
        margin-top: 10px;
    }

    .maxImageSizeSpan {
        font-size: 10px;
        color: $base-danger;
    }

    .maxImageSizeValidation {
        color: $base-danger;
    }


}

.top-profileSection {
    width: 145px;
}

.EventCheckBox {
    .dx-checkbox-text {
        font-size: 12px;
    }
}

.checkbox-width {
    width: 180px;
}

.list-section-email-templates {
    height: 475px !important;
}

.wizard-tab-panel {
    .dx-tabpanel-tabs {
        display: none !important;
    }

    .dx-multiview-wrapper {
        border: none !important;
    }
}

.list-toolbar-skeleto-w_100 {
    background-color: #e7eaec;
    background-image: linear-gradient(90deg, #e7eaec, #eff2f3, #e7eaec);
    background-size: 100px 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    width: 100%;
    height: 65px !important;
    margin: 0px;
    animation: skeleton-animation 1.5s ease-in-out infinite;
}

.list-toolbar-skeleto-HTMLEditor {
    background-color: #e7eaec;
    background-image: linear-gradient(90deg, #e7eaec, #eff2f3, #e7eaec);
    background-size: 100px 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    width: 100%;
    height: 400px !important;
    margin: 0px;
    animation: skeleton-animation 1.5s ease-in-out infinite;
}

.EmailTemplate_Grid_header {
    background-color: $base-common-blue !important;
    height: 30px !important;
}

.Email_Template_Grid {
    height: 440px !important;
    overflow-y: scroll !important;
}

.event-link-copy-button {
    margin-top: 1px !important;
    margin-bottom: 1.5px !important;
    margin-right: 1px !important;
}

.Copied-Event-icon {
    right: 45px !important;
    top: 12px !important;
    background-color: white !important;
    margin-left: 10px !important;

}

.automattic-notification-on-caption {
    color: $base-common-blue;
    font-size: 13.5px;
}

.ImageUploder_Caption {
    font-size: 10.2px !important;
    width: auto !important;
}
