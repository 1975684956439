@import "../../themes/generated/variables.base.scss";
@import "../../utils/custom-additional-variables.scss";

.side-nav-inner-toolbar {
  width: 100%;
}

#navigation-header {
  background-color: $base-light-accent;
  border-bottom: 1px solid $base-border-color;

  .dx-icon-menu::before {
    color: $base-black;
  }

  .menu-button .dx-icon {
    color: $base-text-color;
  }

  .screen-x-small & {
    padding-left: 20px;
  }

  .dx-theme-generic & {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.nav-header-section {
  border-bottom: 1px solid $base-border-color;
}

.nav-header-section-closed {
  padding: 4.5px 12px;
}

#customDrawer {

  .dx-swatch-additional .dx-treeview-item {
    padding: 8px 0px 10px 0px !important;
  }

  .dx-swatch-additional .dx-treeview-toggle-item-visibility {
    // height: 40px !important;
    margin-right: 10px !important;
  }

  .toggle-btn {
    .dx-button-content {
      padding: 10px 0px !important;
    }

    .dx-button {
      border-radius: 4px 0px 0px 4px !important;
    }

    .dx-icon {
      width: 12px;
    }
  }

  .dx-treeview-expander-icon-stub{
    display: unset !important;
    flex-shrink: unset !important;
    width: 0 !important;
    height: 0 !important;
  }
  .dx-swatch-additional .dx-treeview-item{
    gap:0 !important;
    flex-direction: row-reverse !important;
  }
}

.nav-header-logo {
  padding: 8px 0px 6.6px 0px;
}

.toggle-menu-right-btn {

  position: absolute;
  left: 14px;
  top: 7px;
  z-index: 1000;

  .dx-button-content {
    padding: 14px 0px !important;
  }

  .dx-button {
    border-radius: 0px 4px 4px 0px !important;

  }

  .dx-icon {
    width: 12px;
  }
}