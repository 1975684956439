@import "../../themes/generated/variables.base.scss";
@import "../../utils/custom-additional-variables.scss"; 

.single-card {
  .dx-card {
    border-color: transparent;
    margin: auto auto;
    flex-grow: 0;

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }
    .screen-xx-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }
    .screen-ex-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      .Signup_button {
        background-color: $base-accent;
        color: white;
        width: 87px;
      }

      .Signup_button_active {
        background-color: $base-light-teal;
        border-radius: 20px;
        color: white;
        width: 87px;
      }

      .Signin_button {
        background-color: $base-accent;
        color: white;
        width: 110px;
        margin-left: 60px;
      }

      .Signin_button_active {
        background-color: $base-light-teal;
        border-radius: 20px;
        color: white;
        width: 110px;
        margin-left: 60px;
      }

      .title {
        color: $base-accent;
        line-height: 28px;
        font-weight: 500;
        font-size: 21px;
        .screen-x-small & {
          font-size: 20px;
        }
        .screen-xx-small & {
          font-size: 20px;
        }
        .screen-ex-small & {
          font-size: 20px;
        }
      }

      .subtitle {
        color: $base-light-teal;
        font-size: 12px;
      }

      .line {
        color: $base-blue;
      }

      .description {
        color: rgba($base-text-color, alpha($base-text-color) * 0.7);
        line-height: 18px;
      }
    }

    .form-card {
      // width: unset !important;

      .screen-small & {
        width: 16rem !important;
      }

      .screen-medium & {
        width: 17rem !important;
      }

      .screen-large & {
        width: 17rem !important;
      }
      .screen-ex-large & {
        width: 17rem !important;
      }
    }


  }
}