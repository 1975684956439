@import "../../utils/custom-additional-variables.scss";
@import "../../dx-styles.scss";

.visitor-section-width {
    height: fit-content !important;
    width: 650px;
    border: 1px solid $base-light-accent;

    .form-section {
        max-height: calc(100vh - 200px);
    }
}

.registration-closed-page {
    .warning-icon {
        color: $base-dark-yellow;
        font-size: 50px;
    }

    .message {
        color: $base-blue;
        font-size: 16px;
    }
}

.RegistrationPageFileUploader {
    border: 1px solid #ddd;
    border-radius: 4px;
}

.heightForExlarge{
    height: 67vh !important;
}
.heightForLarge{
    height: 57vh !important;
}

.EventLogo_div{
    width: 240px !important;
    height: 116px !important;
}
  
.eventLogo_imageTag{
    width: 100% !important;
    height: 100% !important;
}