@import "../../../themes/generated/variables.base.scss";
@import "../../../utils//custom-additional-variables.scss";

.group-login-form {
   .forgot-password-button .dx-button-content .dx-button-text{
    color: $base-dark-accent;
    font-size: medium;
    .screen-x-small & {
      font-size:small;
    }
    .screen-xx-small & {
      font-size:small;
    }
    .screen-ex-small & {
      font-size:small;
    }
  }
 
}
.group-loaderCart-height{
  height: 380px;
}

.group-indicator{
  margin-top:150px;
}
.loader-for-logo{
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gLogin_completeForm{
  .title {
    color: $base-accent;
    line-height: 28px;
    font-weight: 500;
    font-size: 21px;
    .screen-x-small & {
      font-size: 20px;
    }
    .screen-xx-small & {
      font-size: 20px;
    }
    .screen-ex-small & {
      font-size: 20px;
    }
  }
  
  .subtitle {
    color: $base-light-teal;
    font-size: 12px;
  }
}

.EventLogo_div{
  width: 240px !important;
  height: 116px !important;
}

.eventLogo_imageTag{
  width: 100% !important;
  height: 100% !important;
}