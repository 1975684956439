@import "../../dx-styles.scss";


.RegistrationPageFileUploader {
    border: 1px solid #ddd;
    border-radius: 4px;
}

.removeFileButton {
    .dx-button-content {
        padding: 0 !important;
    }
}

.dx-fileuploader-wrapper {
    height: 75px !important;
}