@import '../../themes/generated/variables.base.scss';
@import '../../dx-styles.scss';
@import '../../utils/custom-additional-variables.scss';
.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 19px;
}

.react-logo {
  width: 184px;
  height: 68px;
}

.plus {
  margin: 23px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;
    &.plus {
      margin: 0;
    }
  }
}

.admin-dashboard-cards{
  width: 525px;
  -webkit-box-shadow: 2px 1px 19px -10px rgba(125,125,125,1);
-moz-box-shadow: 2px 1px 19px -10px rgba(125,125,125,1);
box-shadow: 2px 1px 19px -10px rgba(125,125,125,1);
  border-radius: 2px;
  .counting{
    @extend .font-medium-18;
    color: $base-accent;
    
  }
  .user-type-text{
    @extend .font-medium-14;
    color: $base-gray;
  }
  .card-title{
    background-color:$base-sky-blue;
    border-radius: 4px 4px 0px 0px;
  }
}

.admin-dashboard-list-section{
  height: calc(100vh - 300px);
}
.top-section-close{
  height: calc(100vh - 100px);
}
.admin-dashboard-tab-sm{
  height: calc(100vh - 330px);
}
.top-section-close-sm{
  height: calc(100vh - 100px);
}

.Dashboard_Grid{
  max-width: 100% !important;
  min-width: 400px !important;
  
  .dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-cell-modified::after, .dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-datagrid-invalid::after{
    border-color: transparent !important;
    border-bottom: 0px solid #ddd !important;
}
  .dx-toolbar .dx-toolbar-items-container{
    height: 42px;
  }
  .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit .dx-link {
    display: inline-block;
    padding-right: 4px;
    border-right: 1px solid rgb(204, 204, 204);
}
}
.Dashboard_Group_Filter_Grid{
  max-width: 100% !important;
  min-width: 200px !important;
  max-height: 1600px !important;
  .dx-datagrid-rowsview .dx-row.dx-group-row:first-child {
    display: none !important;
  }
  .dx-toolbar .dx-toolbar-items-container{
    height: 42px;
  }
  .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit .dx-link {
    display: inline-block;
    padding-right: 4px;
    border-right: 1px solid rgb(204, 204, 204);
}
}

.visitor-download-btns{
  border-radius: 8px;
  background-color:  $base-light-green !important;
  color: $base-black !important;
  // margin-top: 10px;
   .dx-button-content {
    padding-block: 6px;
    padding-inline-start: 7px !important;
    padding-inline-end: 7px !important;
    font-size: 13px;
}
}


.photo-row-btn{
  border-radius: 8px;
  color: $base-accent !important;
  font-weight: 500;
  text-decoration: underline;
  background: transparent !important;
  cursor: pointer !important;
  // margin-top: 10px;
   .dx-button-content {
    padding-block: 0px;
    padding-inline-start: 0px !important;
    padding-inline-end: 0px !important;
    font-size: 13px;
}
}

.photo-row-btn:hover{
  color: $base-dark-blue !important;
  cursor: pointer !important;
}


.DashboardTabSection{
  .dx-tabpanel-tabs-position-top .dx-tabs-wrapper {
    border-block-start: none !important;
    border-block-end: none !important;
}
.dx-tabs .dx-tab-text-span{
  @extend .font-medium-16;
}
.candidate-details-top{
  @extend .font-medium-14;
}
.dx-multiview-item{
  overflow-y: auto !important;
}
.dx-tabs {
  display: block;
  box-shadow: none !important;
  background-color: transparent !important;
}
.dx-tab {
  box-shadow: none !important;
  background-color: transparent !important;
}
.dx-tab.dx-tab-selected {
  box-shadow: none !important;
  background-color: $base-light-accent !important;
  border-bottom: 2px solid $base-blue;
  border-left: none !important;
  outline: none;
}
.dx-tabpanel .dx-multiview-wrapper .dx-state-focused {
  // border: none !important;
  box-shadow: none !important;
}
.dx-multiview-wrapper {
  border-top: 1px solid #ddd !important;
}
.dx-tabpanel .dx-multiview-wrapper {
  border: none;
}
.dx-tabpanel > .dx-tabpanel-tabs .dx-tab {
  padding: 7px !important;
}
}
.card-table{
  width: 100%;
  table, th, td {
    border: 1px solid $base-gray;
    border-collapse: collapse;
    padding-left: 5px;
  }
  th{
    font-weight: 500;
  }
}

.focused-card{
    border: 2px solid $base-blue-border;
    border-radius: 4px;
}

.grid-min-width{
  min-width: 300px !important;
}

.event-vertical-line{
  color: $base-gray;
  opacity: .6;
  font-weight: 200;
}

.DownloadDropdownButton{
   .dx-button-content {
    padding: 5px 2px 5px 9px !important;
  }
  .dx-button-mode-outlined.dx-buttongroup-last-item.dx-button {
    border-radius: 8px !important;
    background-color: $base-medium-blue !important;
}
}

.GridRowDropdown{
  .dx-button-mode-outlined.dx-buttongroup-last-item.dx-button {
    border: none !important;
  }
  .dx-button-content {
    padding: 4px 2px 4px 8px!important;
  }
}

.DropdownButtonOrange{
  .dx-button-mode-outlined.dx-buttongroup-last-item.dx-button {
    background-color: rgb(242, 214, 192) !important;
    border: none !important;
}
}

.ButtonPurple{
  .dx-button-mode-outlined.dx-buttongroup-last-item.dx-button {
    background-color: rgb(221, 220, 238) !important;
    border: none !important;
}
}
.ButtonTeal{
  
  .dx-button-mode-outlined.dx-buttongroup-last-item.dx-button {
    background-color: rgb(240, 217, 213) !important;
    border: none !important;
}
}

.ButtonGreen{
  .dx-button-mode-outlined.dx-buttongroup-last-item.dx-button {
    background-color: rgb(195, 219, 194) !important;
    border: none !important;
}
}

.expandCollapseBtn{
  position: absolute;
  right: 0;
  z-index: 1000;
}



.left-section-expand{
  height: 230px;
  border-right: 1px solid rgb(217, 217, 217);
  transition: all .3s;
}

.left-section-collapse {
  height: 0px;
  transition: all .3s;
}

.GroupsText{
  @extend .font-regular-16;
}

.card-skeleton {
  background-color: #e7eaec;
  background-image: linear-gradient(90deg, #e7eaec, #eff2f3, #e7eaec);
  background-size: 100px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  width: 530px;
  height: 210px;
  margin: 6px 0px 6px;
  animation: skeleton-animation 1.5s ease-in-out infinite;
}
@keyframes skeleton-animation {
  0% {
    background-position: -100px 0;
  }
  100% {
    background-position: calc(100px + 100%) 0;
  }
}

.left-grid-skeleton{
  width: 300px;
}
.left-grid-skeleton-row{
  background-color: #e7eaec;
  background-image: linear-gradient(90deg, #e7eaec, #eff2f3, #e7eaec);
  background-size: 100px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  width: 300px;
  height: 30px;
  margin: 6px 0px 6px;
  animation: skeleton-animation 1.5s ease-in-out infinite;
}
@keyframes skeleton-animation {
  0% {
    background-position: -100px 0;
  }
  100% {
    background-position: calc(100px + 100%) 0;
  }
}

.right-grid-skeleton{
  background-color: #e7eaec;
  background-image: linear-gradient(90deg, #e7eaec, #eff2f3, #e7eaec);
  background-size: 100px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  width: 100%;
  height: 30px;
  margin: 6px 0px 6px;
  animation: skeleton-animation 1.5s ease-in-out infinite;
}
@keyframes skeleton-animation {
  0% {
    background-position: -100px 0;
  }
  100% {
    background-position: calc(100px + 100%) 0;
  }
}

.rejected-icon-email-status{
  margin-right: 2px;
}

.registration-deleteBtn{
  .dx-button-content{
    padding: 3px;
  }
}