.custom-skeleton-two {
    background-color: #e7eaec;
    background-image: linear-gradient(90deg, #e7eaec, #eff2f3, #e7eaec);
    background-size: 100px 100%;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: inline-block;
    line-height: 1;
    width: 100%;
    height: 25px;
    margin: 6px 0px 6px;
    animation: skeleton-animation 1.5s ease-in-out infinite;
  }
  @keyframes skeleton-animation {
    0% {
      background-position: -100px 0;
    }
    100% {
      background-position: calc(100px + 100%) 0;
    }
  }