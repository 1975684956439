@import "../../themes/generated/variables.base.scss";
@import "../../utils/custom-additional-variables.scss";

.popup_header{
    background-color: $base-light-accent;
    .dx-toolbar{
        background-color: $base-light-accent;
    }
    .remove-icon{
        
       .dx-button-content{
          padding:2px !important;
        }
        .dx-icon{
            font-size: 15px !important; 
        }
    }
    .save-icon{
        .dx-button-content{
            padding:3px !important;
            width: 26px;
            height: 26px;
            line-height: 26px;
            
          }
          .dx-icon{
            font-size: 15px !important; 
        }
    }
    
}

.popup_header_title{
    color: $base-accent;
}

.footer-text{
    font-size: 10px !important;
    font-style: italic;
}

.footer-accent-text{
    font-size: 11px;
    color: $base-accent;
    font-weight: 400;
}