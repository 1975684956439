@import '../../utils/custom-additional-variables.scss';
.forgot-password-form {
    .request-reset-link {
        color: $base-dark-accent;  
        font-size: medium;

        .screen-x-small & {
            font-size: small;
        }
        .screen-xx-small & {
            font-size: small;
        }
        .screen-ex-small & {
            font-size: small;
        }
    }
    .reset-button{
        .screen-x-small & {
            font-size: x-small;
        } 
        .screen-xx-small & {
            font-size: x-small;
        }
        .screen-ex-small & {
            font-size: x-small;
        }
    }
}
.loaderCart-height{
    height: 230px;
}
.indicator{
    margin-top:25%;
}