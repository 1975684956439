
.dx-icon-EventsLightIcon {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABeUlEQVR4nO2VzUoDQRCEF4JejHfvAUFE9GLUZ9LXyEElrEleRU8+QSA3f3I3Xs1FIQarKGnTkiXsbjZkIi6koA/LNl1fz/TMRNFa/1WS9knGAB4BfAAYAuiRbEqqrdJ4k2QHAEgqLSa/eCmpsgrzezcZkbyRVJe05XFCsgXg02HugkKQ7Lj5i6SDHNBDAAOHaKTU+VmphfccE41mzdMKOsTvShSOvO5jT4qLdkSyHQwAwJMlSDouCuAzYVvWnZdfBODdAaoLAFQd4C2z8AIAwzQASTs5ANsht6DnAPWkOYC+d/mcAnAacgibntRKQF24ed9gso4tyavMwtPcfABJNbvh7GjZEUtAnKeZSzoCMAbwJWl3aQCTXa/e8SAJkWH+6kWvowIqBCCpYterQ9gl07Z9tsH0OPN3YuwFbyVtBANIQDTyBskfo9jejiyDed9zxalZ1865P8kPNqSS9rLygwNEgVR+AAb+Lh/AsiovAANHeQDWWiv6I30Dx6eU93gzbGAAAAAASUVORK5CYII=") center center no-repeat ;  
}

.dx-icon-GroupsLightIcon{
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABvUlEQVR4nO2Vu05CQRCGCRosDGglhRoqLeAhtLP0UlsZI/GGvpMWWkijvoB3G6+FGJ7AxLsWxsLPrP7oinsWhJzGnEkmkDP//vPv7O5MLBZZZJH90YA2YB44AJ7l5v8ckAg7eTdwQrAdG0yYOz9RogtgGEjKR4CSYkehVAIoWMk7HfFOS8RsGAIORT7swYwJsx+GgCeRJz2YlDCPYQi4r0NAhzB3YQjYE/lIHUew02iSfqBoSihfA/oUy4u85LmEl8JM1eILSn7reNvXev+tajgVEaM685R2Xkl+IKyXzyWgKMC6Ehrf0LdVYdLAlacRmVi6Xr5qAaZE2OqAXn0zF3AIKFPbysJ6+VwCHhTscSww9qbfUzWlLNAuzwELwFkVNojPKWBFQVOmHvmmRfSiixh3XqJPjjgwLSwevhXX4gzwGlBWQzgQlNjBNVglwjaTI1O9IA1se841X29yi3PGw7cFdFWACWDXMeXGrTOPNyCgBTgXx7hjau58TE1g0kr+1WCAZX0v/DW5xbEojqWAqTkRs3b/Y8pZoGwTAnKVzQW2bL671Y8hY73jZBMCTNl/TUdrat40yh1ZZP/H3gFSEUbVTj8HXwAAAABJRU5ErkJggg==") center center no-repeat ;  
}



.dx-icon-AppSettingIcon {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB3ElEQVR4nO2Xu0oDURCGF1NYSGzVmEIIKIi+QQr7eGnyDpZaJLGzUTvfwT4PoDYRtBQba8VOiJVX8NJ8MvgvDrrZnGguCP6wkP3P3PbMzDmTKPrHXwTQ5Dua/XKepzUm+xHAipztO+5A3HI/AtiVsx3H7Yjb7ZaTKeAM2ASmxc0DJ267y06+7PhjkxU/LRtma6qTAOpfcnsOPOv3BbAGjDj5EXG2hmRNx6Me6nxBCvfAHnCr9zegAgyl6A5JxmSR7p5sGRbaOc+4yGvihlV4xQ52sCidYb3X3E5m0hRX3dfmU+QWgQbwBDzqd6lN68a7spoWwAxw5XJdSJDZTjkHthLkC642zPZMywAMwBhwKoWbuAvclxtelOsJPVVx+J1QF5gNZHMsCgEfVX0txVnHH4mrJOjEeW44blbcte+a0CDupJx1nOXbMJ4gP661B8dlxd115DwlgAdxEzHn1ibj9nXc6I8CoHUKrNoN1QSdja6kgPQiLLkitJzn9Jjz14QiLHRUhIS14VbP2pDwg6ikdDx2+yDKDPQoDriMqgGXUe3Hl1HgdXwJrCdcx+ta+9113GYgsWEjZCCZ+9VAMvCRLA02eMrZgeMOxS1FvQafR24Scj0PYOB/TP4RdRnvzC3zDl1J4PUAAAAASUVORK5CYII=") center center no-repeat ;  
}


.dx-icon-HomeLightIcon {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAtklEQVR4nO2UDQrCMAyFewotXnE77nB6mk8KVaNu1v6kG5gHhUJJ3pdHiXOmCgGHcLYy98A5Ht/b/AhMPHUFTj0nn6Px/Hb3PSe/hKnjP5jUk2DBXLzpQvDFXB2CH8zVIMgwbw5BgXkzCCrMqyFoYF4MweuSocViiT1JLis+1yu15qK31HISwCgoVwFIKFFz7z2ukQ4yMgWA0HtIBOYeBTlvJTUGYAlYAvtPIGfhlNTsF8D0V7oBFlC/UjQlsXIAAAAASUVORK5CYII=") center center no-repeat ;  
}

.dx-icon-BulletIcon{
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAfUlEQVR4nO2SSwqAMBBDez9bPJQUvFS1V/KzSFZKpQs31dJREGkg27zJzChVVfUPAWgBeABrMMkRgHkknGRPckvYiidnOvwwAC0B+DsAyUECWDIazG8DpmJA+JaMFTlJA5PRoCkGxBb2AtCJwk9NdPiWcJN4FyeevKrqO9oBz85ObAVdroEAAAAASUVORK5CYII=") center center no-repeat ;     
}

.dx-icon-EditRegistrationformIcon{
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA00lEQVR4nGNgGAWkAOOq7d7GldufGFdt/08ONqra/tikaqsnTgtACsg13BhmSeW2R/h8AFFUscOHHGwM1T/wFhhTiBkG3AIGMoHxoLPAGAcfV5AMPgtIBbS3oHL7CqoEEQMa36hyeweIHxq6itm4avtiqltgjGSJff1+FqItIASMq7aVY7OEahaQbAlJxXXl9hWw4CDaElBlQbYlldsbEBZs229cv4mLgdrAuHJ7A8hwg8ptoiDaqHJbHfUtqd/EBfYBpAo9R3ULQADkcpDhevU7xQCWAQVWF5uL2gAAAABJRU5ErkJggg==") center center no-repeat;
}

.dx-icon-MediaRegistrationformIcon{
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAtklEQVR4nL2UTQrCQAxGe6akR9AjeAFPYdILuJq9B3AjuJN8ijdw6c5L1JVYilBxqL90oNRhAg8GJmTC42OyLGaxbMckmA8hLzB6DSKFY0UzBFK4hIPENlxg0omiCg4isf270dZfTT/FgkvvjUhxCElmtTq9I1KcWLDqghS3JI4qVpQPSOzKakua7bgTf5/ckfhXyxiynfehaHK16Wcgvc/+ycaRBYv2fH76awkn+29H0b6RGHUHnJYPbJVVlu8AAAAASUVORK5CYII=') center center no-repeat;
}


.dx-icon-EmailTemplateIcon{
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD+ElEQVR4nO2ZS4gcZRDHe32/FYkiURTxvZLtqmkTHwfXx8GDeBDJQdTgAxYUgsxU9S5BtPEi0d2umkk8uOBJvPg6rOJBPSgaI3oxKIYE9eKDSFBComaTqBmpmfQyO9vd0z2Z6Z0NU1Aw0z3f9/1/3V/VV983jjO0oa0cGy2HFyLL88D6DbIcAtJ5ZN0BLIFXmVnlrARz/fBOIN2DrPVYJ9nrkt7hDLK5rI8i6eFEiAUYPYwkjziDZ/URZHmhI8Ail6PA8mzhUpHl83xCe+Ck2/oAUjAEN71vID3vuOjxcAjSneHwjZzoU2v9+jdPBpJX4joGls96lZ2sLycGxMY2DccF4QWzZwHLXFI67CkI6adxIMcg50xL94Uf6ba+5/UEw6WL45djNH1xrk7GaMuVSLKrkAUqwTB2+umPY5WZa7N14MtNyPJbYSttkg5OmoLyO1Rqt6U2LpHchSx/Zi0Z8sZIe0B3A4JN/wsr1bsTG9umB0k/6BtIW0B3DyIfZtig1UeQdApJ/xu8qSVW+m/umI6BdJOBNDoiuQ9Z9g0OiOwzTc279ZGm1pTGQPKe+7ReYN+9qerlyPLVAIDs8Lh2lV2/zn/xXCB5O1VLS5r73iuHa+zaeBCcgSyvLiPI62M0fbZd8yoz1wPJzo5a2gJzHil8LLpXIpmwvXZBHA6Q/mOxuvC9og82slWW2ZGQabZ4E7On2n2P9OaCOJxoLBvbNORKPInpzkoVf+vq/HLqI245vNQ8SiJ5DP2tq9POCvKDNN/MHvDD8awA6OvjVlK0rMi/AuuTWYHAD8fTKoyuQY6tzP82522yGMsqyPpWch/6rje1+fw0iBLJBLAc6aSna5AWoLk4MZZVkOW7DH3sdiflxvb2o0+9fA6wvpFVx3GDRLndUnPU1q3IQ+1ZpcPD2F/i8P6o/bqNtfMyPoSegvwSVaDjQXCKlQ05H0JsyeGS3BtXTfQHhPSTtfzSJfb7NZO1y4Bke3cQi4A+jjZN4FevQZZv+wgiR5GlFq0pdqLeKavkcWD9Cai6Nk+s5AYB0gPI1Qdaq+NGBusRRMs480DVJ7Jmr1wgjdpmUm+IAhJY3uk1QMzbmR0NgtMamkhvT3rzeUAWCjYohy6Q/NBvCIypJhqxyPpFbpD2gg25+jCQ/F0YBEcwstf+ATMNV2+snW5vKjsIyc/IemvU2AK8cABeFDeLq2DWDcB6MB2E9CPYVLuo+Xn6ivZN1bICsb52Szk807S5k+E6y3KJINHCVKroPXbsstzicelU+9rO3Exj+mFdEJwEpM8lHTwMiP9hDzoZonng8P4ACK13nmaNNeyZ9GBfQe6c+CC09PR9UB1yHL8ObWhO9/Y/FVKUjYj0x7cAAAAASUVORK5CYII=") center center no-repeat;
}

.dx-icon-onApproval{
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABX0lEQVR4nN1UPU/DQAw9geCHEDtFCKawwQB3KSsIWvg5XSkwNXYkdtSBP0SBworK2LAXmahSvnNJmWrJyulyfj6/57NSa239571tw+6NYRgbgolm+BGXtez55FzLmVbgJoArTfBhGBc1PvUZLxvcWm0ahgcL4EXKCe4HA7VRf/M24LxMgsNaWlqDc+ya3YsKQXG6egL4LBQ+7paGYISz09DxfHKPU/sh9Iu4HzcF74Y7+xLrB53DdBX4lEugCd/sacBvP+gcSNzZyN01jF+ZjpoUVRD9CziLQ5RPQDBPHjoZuUdSulBRRIt8k/8yFczzFDG8Jg+JeEkga/AKilIiZwG7tuBlIsvgquoUW/A4DnrK9qFpwplokdWjwt+9R29LFZlMxVVfsiHnXFWZTMX24Hir6kxGrma4awE+tBrXS5OpKHzacF5LS5mJWDK4pO00w0v82iGS9d8eQa9U0LWxX51WYrjbvQzhAAAAAElFTkSuQmCC")center center no-repeat;
}


.dx-icon-onRejectedIcon{
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC50lEQVR4nO1aTW/TQBBdOEHhBvcgeilJ/cYhlx4RAn4CcAUuoKqcKj4q4Ne03BEVh1ahqQqV4p0UDvwYGqkJGjdJTR273s3aTqo+aaQo6+y+Nzv7MeModYFzBG405tjz7gbAUw2saKK3oQEr8p20yTNqmsC12jwDHxn4wUCXifqpdvzMnib6EHje7dKIa99/yMB3TdQ7k3SCaaKeJmoGvv+gMOJtohoDLVvSKTOzc+D71dyI95W6zMD7TGFiL6LLwLu+Upeckv9TrV5nom+5EaeYkE0Z0wl5bjRuakAXRp5GIgIZe1LycxrYL5w8DRY5oK1nIoz5IsOGEkQQfbVaE+x5a2WT5xMRb2y2yu4UCTjs1Ot3MgvQRLtlk+a4iGa20CF6VDZZTjLgfhbvNw280tPAOgMbJlcK298xsJ3u/Vpt3ogIsD4SDrzQREcZyB/Js6MxgQ0T4b+IbiULAD4ZxWVEQBYRp8mbCgjN89ZUEsIrsdnCihFKEmHyLKePuZucjFhsnVmIuSLPgy11f2npalyAZEuGnUVjk4FX0f46RM9C4scL9mW0LQCe25DngR34PsUEhCmfZYepXnbkef7fHsdnAHg9YadjZ8Kl5/nElnO7+ySJcEi+L4lVrgJOx3x0TeQnAG5CaBz5HEQsn8NF7LndRocxn9bGLrfRMH0kOpyBg+zv2IMsFEG055q8cxFAayz5UICUCKf9MocxO9AQUquc6eu0TUIzIPDZNKGx+R0DW+osSKHVdGEVZZronsoCKbSWTZZN08kopEo802UVgVSJp8j7q8qynL5ZOnmiL9bl9kGa+bNEzwcTl9mlxB2Wuosn324vLNxQLiBekCpxkWHzG7imXELiUKrENhe+rKalb2DV+SumKGQ7MzmtDUJmmxcXF1RRkEKrDDrpa1YGtjKfsHlALleST0vFLEt4DcKkJbfKTr1eUdMESTTaRH4HeCJ56/CvBvJZ0kBp26lUrpTN8wLKIf4BfB289UMBVjEAAAAASUVORK5CYII=")center center no-repeat;
}

.dx-icon-onPrintedIcon{
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABMElEQVR4nO2YQU7EMAxFveISDBfgBCxqze1gWI7kiDuwRsOeXoFFPGync42iQiUEZUQCjVOj/yUvI+f5/yhtiCDH4qC9ZRFAflDxSY0yc6RYg1EASRUcyRTjjCQK0coUI1qJQrQsB3Z5/3zGoreN6NH64/AX1bHoZtjzdAqimwVssM+pJujNBMSJE/0nENHjyVx6KwJIsIxOfLi60/P1dr9i0Z1bR9bb/epjfy8XAOHaroju3mL17sajW0c4oQDCC3CB4UioP3mGIyFjCjOLrRwByJId4aDtDIe2/RcgjcSn6iAlxH8E6dyBSDxMFg8/8obRaucAaSReTxYPTysjTFca5OuZyAaReBggvn0OSpF1tMhtg5ogJe8R8gZy6h4hSxCXfRggC3WEjYoAAlF1vQLBYhSlobQZMwAAAABJRU5ErkJggg==")center center no-repeat;
}

.dx-icon-onUserRegistrationIcon{
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABr0lEQVR4nO3Wv0vDQBQH8ID+Aw4FR9HBQVcH63sNLuIoiODu6D/gP6Au4lIcdHDSxUnooIj1vbQgzqLmXUSK2MEiKArqVCpnSYn9mbaX6uAXDkJC8nnvLjliWf/5i/HS8RFFuKUIPCF8VwxPiuHQTcdHI0OFYE4YPhVjqXoIw6M4uOxlIGa8U/nusBb9WQDmr8geNAYLQ7IVWsEJV43BilFCw4xXxmBpsLZ1B+GbOZiw8CuwYjwKDTOeGoOFMdXGGqdMwvnwMDwYgxXjZvjPCTaMwTp67UKgJ5bpCMFsK9hzcMaKIophvcmOtRIJ6qcRbEUd1SHskj0kjBfC8CIOLrWFemc433jHgsVm996mp8YqOyDhc0usdLDQ53JiWgj2hbDYZI2LimBPv4REdn+wU43q4xuyxzUuBOeNK+TEpDDulv8yQm+XuoBX4cSEjyqGnMY06neuz9dOZwZiiuG4HSyI6oKDaOBawe+8JpdZGBDCO9NoeUCubqc6wrDWc1RHCK87QD9cB+Mdozr6IW3DDMmu0G4jjDtVM3GvsjBsRR3FmO05WobBFQJHCLfDTO8XPq+r0PdCI9kAAAAASUVORK5CYII=")center center no-repeat;
}

.dx-icon-TickIcon{
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD+klEQVR4nO2aS49URRTHa3AhuDBx4U5XQoaFD0SiJAx96s6G1ozu6pweWbDiYeJ3EMKG8Jg4kUf8CLJwSUi0+5xGGCCiZLYqMMTHQpgxIUJ4zKRN9TQZw3TPdNWtW9UT7j85mcX0vbd+/zq3qm7VUapUqVKlSpUqVMDjm4HNAS10BhjrwHhDM85pxsedmAPB3+z/tOBp3cT9Wd0Mq7UsYLNFC06A0B9aqOUX+Dswnsjq+I5aE2qpoYrgx1rosj90j2CaAqYx+ww1iBptmve14I/BwZ8JYLoKXNumBkXbp8wGLfgVMC4UDb9kAi4A4yTwnvVJ4bO6GQbG6VjgXeJ6RcY3JYHXjdoHIHQ3IXxnbMA5YDMSFb4i9KFmepAcfmlcuA9cq0aBB8btIPhvaujlmUAPCs+ErG6GtdA/yWF7Z8JsYWMC8J71mvHn1JCrm4DTdmYqwAA8mRrOwYQvA8PXtoHQ/BoyYMEuzMLQt9RQjBVeAXE5yLI5k9onAwDjFXa6zm2ALuLDJlIA46Vc8BWpvZsaohNPgOkzLXTY9drRC7W3c/Q+TiSHZ7qXCX70tE3AeMjtHnjM2wDItZkRIIUFZ4DxzWXtcjABmG77wfP45sS9fx1492vd20YHnYxks9HDAHMgXdrjt2PXxl4KAW8jE9rnbIAWOpMk7RknVeuLdaHgO/c86W4AU8P9QfRNe0+Q8S/na4XmgenzXu3xhe/Ed+4GCN5yS1v6e+SCedVea/9qobMO195badGSE95mwA1nA4Bp1rEHzbP36CcbgPHP0SZuLQp+MfCOjwGPHF0+0u0+Iz98+gowft3jmumdTXq9WPh25zws3AC9ggndsgGEzlev7H65aHh/A8Rvw9M2vNc9n44NdqQ3Z80LMeC9XwHNdNPb8RVMWE3h4T0HQe0xDfb7OsSEzzENUu6FkIsJBcJ7LoSauD/Mw1d/HYqEX2yD2eu7Bd4K1ANHUsF7fwxZ2fP5cI1YnglR4AVnlK+A8UTYnljKhBjwi4FHc1Z5UOB0pIPx4O3GqHlL5ZFmmorV2OBmC13MBW9ly1JSg/hnW4gT45YasmUpz92W+P+VNcx7a+poTGjebumrkALGydRg/QdOqNCqnqu+CEw/PbfH41a2+MB1pyhy6t/N6vSGKr44CgeyREY3cIeKIeBa1RYmDU7a033dwF0qQWXondTw7aLrWD3fbUxIWTcEQtcKf+f7KZ6yNTkx1wmLz8IJOzOpQdFoE7fGKKawK7zgi5yQsmf5tpEF9PrFaNWgIWQrM4DpuD2f94fGGVvgkPuTNrUq7QWU2QtMp0Dwey30q11Q2cOXTsxqxl/s7m37N+3fem5jlSpVqlSpUqpf/QdxTWTd1nMWMAAAAABJRU5ErkJggg==")center center no-repeat;
}