@import '../../utils/custom-additional-variables.scss';
.Invalid-page{
    .warning-icon{
        color: $base-dark-yellow;
        font-size: 50px;
    }
    .message{
        color: $base-blue;
        font-size: 16px;
    }
}