@import "../../themes/generated/variables.base.scss";
@import "../../utils/custom-additional-variables.scss";

.request-reset-link{
    .check-icon{
        color: $base-white;
        background-color: $base-green;
    }
    .message{
        color:$base-accent;
        font-size: 17px;
    }
    .instruction{
        color: $base-light-teal;
        font-size: 12px;
    }
    .GoToLogin-button{
        color: $base-dark-accent;
        font-size: 13px;
    }
}