@import '../../themes/generated/variables.base.scss';
@import '../../utils/custom-additional-variables.scss';
.registration-for-text{
color: $base-text-color !important;
}

.dx-datagrid-search-panel {
  margin-left:0px !important;
}
.GroupDashboardDataGrid{
 
   
    .dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-cell-modified::after, .dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-datagrid-invalid::after{
        border-color: transparent !important;
        border-bottom: 0px solid #ddd !important;
    }

    .screen-ex-small & {
      
      .dx-datagrid-header-panel .dx-toolbar-menu-container .dx-button{
        margin-left: 0px !important;
      }
      .dx-datagrid-search-panel {
        margin:0px !important;
    }
    }
    .screen-xx-small & {
      .dx-datagrid-header-panel .dx-toolbar-menu-container .dx-button{
        margin-left: 0px !important;
      }
      .dx-datagrid-search-panel {
        margin:0px !important;
    }
    }
    .screen-x-small & {
      .dx-datagrid-search-panel {
        margin:0px !important;
    }
      .dx-datagrid-header-panel .dx-toolbar-menu-container .dx-button{
        margin-left: 0px !important;
      }
    }
    
}

.photo-row-btn{
    border-radius: 8px;
    color: $base-accent !important;
    font-weight: 500;
    text-decoration: underline;
    background: transparent !important;
    cursor: pointer !important;
    // margin-top: 10px;
     .dx-button-content {
      padding-block: 0px;
      padding-inline-start: 0px !important;
      padding-inline-end: 0px !important;
      font-size: 13px;
  }
  }
  
  .photo-row-btn:hover{
    color: $base-dark-blue !important;
    cursor: pointer !important;
  }
  
  .green-add-button{
    background: $base-green-button !important;
  }
.green-add-button-sm{
  background: $base-green-button !important;
  .dx-button-content{
    border: none !important;
    outline: none !important;
    padding: 6px 6px 6px 4px !important;
    .dx-icon {
      color: #fff;
      font-size: 16px !important;
      margin-right: 2px !important;
  }
}
}

  .dropdown-btn-with-text{
    
    .dx-button-has-text .dx-button-content{

        background: $base-secondary-red !important;
        border: none !important;
        outline: none !important;
      
    }
    .dx-button-mode-contained.dx-buttongroup-last-item.dx-button{
        background-color: $base-secondary-red !important;
        border-radius: 6px;
        
    }
  }

  .dropdown-btn-with-text-sm{
    .dx-button-has-text .dx-button-content{

        background: $base-secondary-red !important;
        border: none !important;
        outline: none !important;
        padding: 6px 6px 6px 4px !important;

    }
    .dx-button-mode-contained.dx-buttongroup-last-item.dx-button{
        background-color: $base-secondary-red !important;
        border-radius: 6px;
    }
  }