@import "../../../utils//custom-additional-variables.scss";

.Event-Group-Filter-Heading {
    background-color: $base-common-blue
}

.Status-Filter-Heading {
    font-size: 16px !important;
    color: $base-light-yellow;
}

.Event-List-Title {
    color: $base-common-blue;
    font-size: 16px;
    font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
}

.Add-EventGrup-button {
    .dx-button-content {
        padding: 2px;
    }
    i.dx-icon.dx-icon-plus {
        width: 14px !important;
        height: 14px !important;
        background-size: 14px 14px !important;
        font-size: 14px !important;
        line-height: 14px !important;
      }
      i.dx-icon.dx-icon-EditRegistrationformIcon {
        width: 16px !important;
        height: 16px !important;
        background-size: 15px 15px !important;
        font-size: 16px !important;
        line-height: 16px !important;
      }
}

.side-list-section{
    height: calc(100vh - 125px);
}
.vertical-line-inside-grid{
    color: $base-gray;
    line-height: 20px !important;
    font-size: 17px;
    background-size: 24px 24px;
    font-weight: 100;
}