@import "../../themes/generated/variables.base.scss";
@import "../../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  border-bottom: 1px solid $base-border-color;

  .dx-toolbar .dx-toolbar-item.menu-button>.dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }

  .dx-toolbar {
    padding: 10px 0;
  }

  .default-button{
    .dx-button-content {
      padding: 6px;
  
    }
  }
  .ResetButton{
    .dx-icon-pulldown{
      font-size: 18px !important;
      padding: 0 !important;
    }
  }
  .editor-back-btn{
    .dx-button-content {
      padding: 0px 0px !important;
  
    }
  }

}


.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 5px;
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title {

  @extend .font-semiBold-20;

  .screen-ex-small & {
    font-size: medium;
  }

  .dx-item-content {
    padding: 0;
    margin: 0;
    color:$base-accent;
  }
}

.event-title{
  @extend .font-semiBold-18;
}


.dx-theme-generic {

  .user-button>.dx-button-content {
    padding: 3px;
  }
}

.toggle-menu-right-btn {
  .dx-button-content {
    padding: 10px 0px !important;
  }

  .dx-button {
    border-radius: 0px 4px 4px 0px !important;

  }

  .dx-icon {
    width: 12px;
  }
}




.export-dropdown-btn {

  .dx-widget,
  .dx-widget *,
  .dx-widget ::after,
  .dx-widget ::before,
  .dx-widget::after,
  .dx-widget::before {
    box-sizing: border-box;
    color: $base-accent !important;
  }

  .dx-button-mode-contained.dx-buttongroup-last-item.dx-button,
  .dx-button-mode-contained.dx-buttongroup-last-item.dx-state-hover .dx-button-content,
  .dx-button-mode-outlined.dx-buttongroup-last-item.dx-button,
  .dx-button-mode-outlined.dx-buttongroup-last-item.dx-state-hover .dx-button-content {
    border-radius: 100px;
    border-color: $base-accent;
  }
}

.dx-toolbar-menu-section .dx-toolbar-item-content>.dx-button {
  width: auto !important;
}

.dx-dropdownbutton:not(.dx-dropdownbutton-has-arrow) .dx-button-has-icon:not(.dx-button-has-text) .dx-button-content {
  padding: 6px !important;
}


.status-filter-header{
  margin-top: -6px;
}