@import "../../themes/generated/variables.base.scss";
@import "../../utils/custom-additional-variables.scss";

.successful-form-submit{
    .check-icon{
        color: $base-white;
        background-color: $base-green;
    }
    .message{
        color:$base-accent;
        font-size: 15px;
    }
    .sign-in-link {
        color: $base-dark-accent;
        font-size: small;

        .screen-x-small & {
            font-size: small;
        }
        .screen-xx-small & {
            font-size: small;
        }
        .screen-ex-small & {
            font-size: small;
        }
    }
}