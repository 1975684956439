/** Generated by the DevExpress ThemeBuilder
* Version: 23.2.5
* http://js.devexpress.com/ThemeBuilder/
*/

@font-face {
  font-family: DXIcons;
  src: local("DevExtreme Generic Icons"),local("devextreme_generic_icons"),url("../../../node_modules/devextreme/dist/css/icons/dxicons.woff2") format("woff2"),url("../../../node_modules/devextreme/dist/css/icons/dxicons.woff") format("woff"),url("../../../node_modules/devextreme/dist/css/icons/dxicons.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
.dx-swatch-additional .dx-validationsummary-item {
  cursor: pointer;
}
.dx-swatch-additional .dx-invalid-message.dx-overlay {
  position: relative;
}
.dx-swatch-additional .dx-invalid-message.dx-overlay-wrapper {
  width: 100%;
  visibility: hidden;
  pointer-events: none;
}
.dx-swatch-additional .dx-invalid-message > .dx-overlay-content {
  display: inline-block;
  border-width: 0;
  padding: 10px;
  font-size: .85em;
  line-height: normal;
  word-wrap: break-word;
}
.dx-swatch-additional .dx-dropdownbox.dx-dropdowneditor-active .dx-invalid-message-auto,
.dx-swatch-additional .dx-invalid-message-visible.dx-invalid .dx-invalid-message-auto,
.dx-swatch-additional .dx-invalid-message.dx-invalid-message-always,
.dx-swatch-additional .dx-lookup.dx-dropdowneditor-active .dx-invalid-message-auto,
.dx-swatch-additional .dx-state-focused.dx-invalid .dx-invalid-message-auto {
  visibility: visible;
}
.dx-swatch-additional .dx-validationsummary-item-content {
  border-bottom: 1px dashed;
  display: inline-block;
  line-height: normal;
}
@keyframes dx-valid-badge-frames {
  from {
    opacity: 0;
    transform: scale(.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.dx-theme-generic-typography .dx-swatch-additional,
.dx-theme-generic-typography.dx-swatch-additional {
  background-color: #2a2a2a;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  font-family: "Helvetica Neue","Segoe UI",helvetica,verdana,sans-serif;
  line-height: 1.35715;
}
.dx-theme-generic-typography .dx-swatch-additional input,
.dx-theme-generic-typography .dx-swatch-additional textarea,
.dx-theme-generic-typography.dx-swatch-additional input,
.dx-theme-generic-typography.dx-swatch-additional textarea {
  font-family: "Helvetica Neue","Segoe UI",helvetica,verdana,sans-serif;
  line-height: 1.35715;
}
.dx-theme-generic-typography .dx-swatch-additional .dx-font-xl,
.dx-theme-generic-typography .dx-swatch-additional h1,
.dx-theme-generic-typography.dx-swatch-additional .dx-font-xl,
.dx-theme-generic-typography.dx-swatch-additional h1 {
  font-weight: 200;
  font-size: 34px;
}
.dx-theme-generic-typography .dx-swatch-additional .dx-font-l,
.dx-theme-generic-typography .dx-swatch-additional h2,
.dx-theme-generic-typography.dx-swatch-additional .dx-font-l,
.dx-theme-generic-typography.dx-swatch-additional h2 {
  font-weight: 400;
  font-size: 28px;
}
.dx-theme-generic-typography .dx-swatch-additional h3,
.dx-theme-generic-typography.dx-swatch-additional h3 {
  font-weight: 400;
  font-size: 22px;
}
.dx-theme-generic-typography .dx-swatch-additional .dx-font-m,
.dx-theme-generic-typography.dx-swatch-additional .dx-font-m {
  font-weight: 400;
  font-size: 20px;
}
.dx-theme-generic-typography .dx-swatch-additional .dx-font-s,
.dx-theme-generic-typography .dx-swatch-additional h4,
.dx-theme-generic-typography.dx-swatch-additional .dx-font-s,
.dx-theme-generic-typography.dx-swatch-additional h4 {
  font-weight: 500;
  font-size: 18px;
}
.dx-theme-generic-typography .dx-swatch-additional h5,
.dx-theme-generic-typography.dx-swatch-additional h5 {
  font-weight: 700;
  font-size: 16px;
}
.dx-theme-generic-typography .dx-swatch-additional .dx-font-xs,
.dx-theme-generic-typography .dx-swatch-additional h6,
.dx-theme-generic-typography .dx-swatch-additional small,
.dx-theme-generic-typography.dx-swatch-additional .dx-font-xs,
.dx-theme-generic-typography.dx-swatch-additional h6,
.dx-theme-generic-typography.dx-swatch-additional small {
  font-weight: 800;
  font-size: 12px;
}
.dx-theme-generic-typography .dx-swatch-additional a,
.dx-theme-generic-typography.dx-swatch-additional a {
  color: #337ab7;
}
.dx-swatch-additional .dx-icon-accountbox,
.dx-swatch-additional .dx-icon-add,
.dx-swatch-additional .dx-icon-addcolumnleft,
.dx-swatch-additional .dx-icon-addcolumnright,
.dx-swatch-additional .dx-icon-addrowabove,
.dx-swatch-additional .dx-icon-addrowbelow,
.dx-swatch-additional .dx-icon-addtable,
.dx-swatch-additional .dx-icon-airplane,
.dx-swatch-additional .dx-icon-aligncenter,
.dx-swatch-additional .dx-icon-alignjustify,
.dx-swatch-additional .dx-icon-alignleft,
.dx-swatch-additional .dx-icon-alignright,
.dx-swatch-additional .dx-icon-arrowback,
.dx-swatch-additional .dx-icon-arrowdown,
.dx-swatch-additional .dx-icon-arrowleft,
.dx-swatch-additional .dx-icon-arrowright,
.dx-swatch-additional .dx-icon-arrowup,
.dx-swatch-additional .dx-icon-background,
.dx-swatch-additional .dx-icon-bell,
.dx-swatch-additional .dx-icon-blockquote,
.dx-swatch-additional .dx-icon-bold,
.dx-swatch-additional .dx-icon-bookmark,
.dx-swatch-additional .dx-icon-box,
.dx-swatch-additional .dx-icon-bulletlist,
.dx-swatch-additional .dx-icon-car,
.dx-swatch-additional .dx-icon-card,
.dx-swatch-additional .dx-icon-cart,
.dx-swatch-additional .dx-icon-cellproperties,
.dx-swatch-additional .dx-icon-chart,
.dx-swatch-additional .dx-icon-checklist,
.dx-swatch-additional .dx-icon-clear,
.dx-swatch-additional .dx-icon-clearcircle,
.dx-swatch-additional .dx-icon-clearformat,
.dx-swatch-additional .dx-icon-clock,
.dx-swatch-additional .dx-icon-close,
.dx-swatch-additional .dx-icon-codeblock,
.dx-swatch-additional .dx-icon-color,
.dx-swatch-additional .dx-icon-columnproperties,
.dx-swatch-additional .dx-icon-comment,
.dx-swatch-additional .dx-icon-dataarea,
.dx-swatch-additional .dx-icon-datapie,
.dx-swatch-additional .dx-icon-datatrending,
.dx-swatch-additional .dx-icon-datausage,
.dx-swatch-additional .dx-icon-decreaseindent,
.dx-swatch-additional .dx-icon-doc,
.dx-swatch-additional .dx-icon-download,
.dx-swatch-additional .dx-icon-edit,
.dx-swatch-additional .dx-icon-email,
.dx-swatch-additional .dx-icon-errorcircle,
.dx-swatch-additional .dx-icon-event,
.dx-swatch-additional .dx-icon-eventall,
.dx-swatch-additional .dx-icon-favorites,
.dx-swatch-additional .dx-icon-find,
.dx-swatch-additional .dx-icon-folder,
.dx-swatch-additional .dx-icon-fontsize,
.dx-swatch-additional .dx-icon-food,
.dx-swatch-additional .dx-icon-formula,
.dx-swatch-additional .dx-icon-gift,
.dx-swatch-additional .dx-icon-globe,
.dx-swatch-additional .dx-icon-group,
.dx-swatch-additional .dx-icon-growfont,
.dx-swatch-additional .dx-icon-header,
.dx-swatch-additional .dx-icon-help,
.dx-swatch-additional .dx-icon-home,
.dx-swatch-additional .dx-icon-image,
.dx-swatch-additional .dx-icon-increaseindent,
.dx-swatch-additional .dx-icon-indent,
.dx-swatch-additional .dx-icon-info,
.dx-swatch-additional .dx-icon-italic,
.dx-swatch-additional .dx-icon-key,
.dx-swatch-additional .dx-icon-like,
.dx-swatch-additional .dx-icon-link,
.dx-swatch-additional .dx-icon-map,
.dx-swatch-additional .dx-icon-mention,
.dx-swatch-additional .dx-icon-menu,
.dx-swatch-additional .dx-icon-mergecells,
.dx-swatch-additional .dx-icon-money,
.dx-swatch-additional .dx-icon-music,
.dx-swatch-additional .dx-icon-orderedlist,
.dx-swatch-additional .dx-icon-ordersbox,
.dx-swatch-additional .dx-icon-overflow,
.dx-swatch-additional .dx-icon-percent,
.dx-swatch-additional .dx-icon-photo,
.dx-swatch-additional .dx-icon-plus,
.dx-swatch-additional .dx-icon-preferences,
.dx-swatch-additional .dx-icon-product,
.dx-swatch-additional .dx-icon-redo,
.dx-swatch-additional .dx-icon-refresh,
.dx-swatch-additional .dx-icon-remove,
.dx-swatch-additional .dx-icon-removecolumn,
.dx-swatch-additional .dx-icon-removerow,
.dx-swatch-additional .dx-icon-removetable,
.dx-swatch-additional .dx-icon-rowproperties,
.dx-swatch-additional .dx-icon-runner,
.dx-swatch-additional .dx-icon-save,
.dx-swatch-additional .dx-icon-search,
.dx-swatch-additional .dx-icon-shrinkfont,
.dx-swatch-additional .dx-icon-splitcells,
.dx-swatch-additional .dx-icon-strike,
.dx-swatch-additional .dx-icon-subscript,
.dx-swatch-additional .dx-icon-superscript,
.dx-swatch-additional .dx-icon-tableproperties,
.dx-swatch-additional .dx-icon-tags,
.dx-swatch-additional .dx-icon-taskcomplete,
.dx-swatch-additional .dx-icon-taskhelpneeded,
.dx-swatch-additional .dx-icon-taskinprogress,
.dx-swatch-additional .dx-icon-taskrejected,
.dx-swatch-additional .dx-icon-taskstop,
.dx-swatch-additional .dx-icon-tel,
.dx-swatch-additional .dx-icon-tips,
.dx-swatch-additional .dx-icon-to,
.dx-swatch-additional .dx-icon-todo,
.dx-swatch-additional .dx-icon-toolbox,
.dx-swatch-additional .dx-icon-underline,
.dx-swatch-additional .dx-icon-undo,
.dx-swatch-additional .dx-icon-user,
.dx-swatch-additional .dx-icon-variable,
.dx-swatch-additional .dx-icon-verticalalignbottom,
.dx-swatch-additional .dx-icon-verticalaligncenter,
.dx-swatch-additional .dx-icon-verticalaligntop,
.dx-swatch-additional .dx-icon-video {
  background-position: 0 0;
  background-repeat: no-repeat;
}
.dx-swatch-additional .dx-icon {
  background-position: 50% 50%;
  display: inline-block;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dx-swatch-additional .dx-svg-icon svg {
  pointer-events: none;
}
.dx-swatch-additional .dx-rtl .dx-icon-spinnext::before {
  content: "\f04f";
}
.dx-swatch-additional .dx-rtl .dx-icon-spinprev::before {
  content: "\f04e";
}
.dx-swatch-additional .dx-rtl .dx-icon-chevronnext::before {
  content: "\f012";
}
.dx-swatch-additional .dx-rtl .dx-icon-back::before,
.dx-swatch-additional .dx-rtl .dx-icon-chevronprev::before {
  content: "\f010";
}
.dx-swatch-additional .dx-rtl .dx-icon-undo::before {
  content: "\f093";
}
.dx-swatch-additional .dx-rtl .dx-icon-redo::before {
  content: "\f04c";
}
.dx-swatch-additional .dx-rtl .dx-icon-hidepanel::before {
  content: "\f11d";
}
.dx-swatch-additional .dx-rtl .dx-icon-showpanel::before {
  content: "\f11c";
}
.dx-swatch-additional .dx-icon-accountbox,
.dx-swatch-additional .dx-icon-activefolder,
.dx-swatch-additional .dx-icon-add,
.dx-swatch-additional .dx-icon-addtableheader,
.dx-swatch-additional .dx-icon-airplane,
.dx-swatch-additional .dx-icon-aligncenter,
.dx-swatch-additional .dx-icon-alignjustify,
.dx-swatch-additional .dx-icon-alignleft,
.dx-swatch-additional .dx-icon-alignright,
.dx-swatch-additional .dx-icon-arrowback,
.dx-swatch-additional .dx-icon-arrowdown,
.dx-swatch-additional .dx-icon-arrowleft,
.dx-swatch-additional .dx-icon-arrowright,
.dx-swatch-additional .dx-icon-arrowup,
.dx-swatch-additional .dx-icon-attach,
.dx-swatch-additional .dx-icon-back,
.dx-swatch-additional .dx-icon-background,
.dx-swatch-additional .dx-icon-bell,
.dx-swatch-additional .dx-icon-belloutline,
.dx-swatch-additional .dx-icon-blockquote,
.dx-swatch-additional .dx-icon-bmpfile,
.dx-swatch-additional .dx-icon-bold,
.dx-swatch-additional .dx-icon-bookmark,
.dx-swatch-additional .dx-icon-box,
.dx-swatch-additional .dx-icon-bulletlist,
.dx-swatch-additional .dx-icon-car,
.dx-swatch-additional .dx-icon-card,
.dx-swatch-additional .dx-icon-cart,
.dx-swatch-additional .dx-icon-cellproperties,
.dx-swatch-additional .dx-icon-chart,
.dx-swatch-additional .dx-icon-check,
.dx-swatch-additional .dx-icon-checklist,
.dx-swatch-additional .dx-icon-chevrondoubleleft,
.dx-swatch-additional .dx-icon-chevrondoubleright,
.dx-swatch-additional .dx-icon-chevrondown,
.dx-swatch-additional .dx-icon-chevronleft,
.dx-swatch-additional .dx-icon-chevronnext,
.dx-swatch-additional .dx-icon-chevronprev,
.dx-swatch-additional .dx-icon-chevronright,
.dx-swatch-additional .dx-icon-chevronup,
.dx-swatch-additional .dx-icon-clear,
.dx-swatch-additional .dx-icon-clearcircle,
.dx-swatch-additional .dx-icon-clearformat,
.dx-swatch-additional .dx-icon-clearsquare,
.dx-swatch-additional .dx-icon-clock,
.dx-swatch-additional .dx-icon-close,
.dx-swatch-additional .dx-icon-codeblock,
.dx-swatch-additional .dx-icon-coffee,
.dx-swatch-additional .dx-icon-collapse,
.dx-swatch-additional .dx-icon-color,
.dx-swatch-additional .dx-icon-columnchooser,
.dx-swatch-additional .dx-icon-columnfield,
.dx-swatch-additional .dx-icon-columnproperties,
.dx-swatch-additional .dx-icon-comment,
.dx-swatch-additional .dx-icon-contains,
.dx-swatch-additional .dx-icon-contentlayout,
.dx-swatch-additional .dx-icon-copy,
.dx-swatch-additional .dx-icon-cut,
.dx-swatch-additional .dx-icon-dataarea,
.dx-swatch-additional .dx-icon-datafield,
.dx-swatch-additional .dx-icon-datapie,
.dx-swatch-additional .dx-icon-datatrending,
.dx-swatch-additional .dx-icon-datausage,
.dx-swatch-additional .dx-icon-decreaseindent,
.dx-swatch-additional .dx-icon-decreaselinespacing,
.dx-swatch-additional .dx-icon-deletecolumn,
.dx-swatch-additional .dx-icon-deleterow,
.dx-swatch-additional .dx-icon-deletetable,
.dx-swatch-additional .dx-icon-description,
.dx-swatch-additional .dx-icon-detailslayout,
.dx-swatch-additional .dx-icon-doc,
.dx-swatch-additional .dx-icon-docfile,
.dx-swatch-additional .dx-icon-docxfile,
.dx-swatch-additional .dx-icon-doesnotcontain,
.dx-swatch-additional .dx-icon-download,
.dx-swatch-additional .dx-icon-dragvertical,
.dx-swatch-additional .dx-icon-edit,
.dx-swatch-additional .dx-icon-edittableheader,
.dx-swatch-additional .dx-icon-email,
.dx-swatch-additional .dx-icon-endswith,
.dx-swatch-additional .dx-icon-equal,
.dx-swatch-additional .dx-icon-errorcircle,
.dx-swatch-additional .dx-icon-event,
.dx-swatch-additional .dx-icon-eventall,
.dx-swatch-additional .dx-icon-expand,
.dx-swatch-additional .dx-icon-expandform,
.dx-swatch-additional .dx-icon-export,
.dx-swatch-additional .dx-icon-exportpdf,
.dx-swatch-additional .dx-icon-exportselected,
.dx-swatch-additional .dx-icon-exportxlsx,
.dx-swatch-additional .dx-icon-eyeclose,
.dx-swatch-additional .dx-icon-eyeopen,
.dx-swatch-additional .dx-icon-favorites,
.dx-swatch-additional .dx-icon-fieldchooser,
.dx-swatch-additional .dx-icon-fields,
.dx-swatch-additional .dx-icon-file,
.dx-swatch-additional .dx-icon-fill,
.dx-swatch-additional .dx-icon-filter,
.dx-swatch-additional .dx-icon-find,
.dx-swatch-additional .dx-icon-floppy,
.dx-swatch-additional .dx-icon-folder,
.dx-swatch-additional .dx-icon-font,
.dx-swatch-additional .dx-icon-fontsize,
.dx-swatch-additional .dx-icon-food,
.dx-swatch-additional .dx-icon-formula,
.dx-swatch-additional .dx-icon-fullscreen,
.dx-swatch-additional .dx-icon-gift,
.dx-swatch-additional .dx-icon-globe,
.dx-swatch-additional .dx-icon-greater,
.dx-swatch-additional .dx-icon-greaterorequal,
.dx-swatch-additional .dx-icon-group,
.dx-swatch-additional .dx-icon-growfont,
.dx-swatch-additional .dx-icon-header,
.dx-swatch-additional .dx-icon-help,
.dx-swatch-additional .dx-icon-hidepanel,
.dx-swatch-additional .dx-icon-hierarchy,
.dx-swatch-additional .dx-icon-home,
.dx-swatch-additional .dx-icon-image,
.dx-swatch-additional .dx-icon-imgarlock,
.dx-swatch-additional .dx-icon-imgarunlock,
.dx-swatch-additional .dx-icon-import,
.dx-swatch-additional .dx-icon-importselected,
.dx-swatch-additional .dx-icon-inactivefolder,
.dx-swatch-additional .dx-icon-increaseindent,
.dx-swatch-additional .dx-icon-increaselinespacing,
.dx-swatch-additional .dx-icon-indent,
.dx-swatch-additional .dx-icon-indeterminatestate,
.dx-swatch-additional .dx-icon-info,
.dx-swatch-additional .dx-icon-insertcolumnleft,
.dx-swatch-additional .dx-icon-insertcolumnright,
.dx-swatch-additional .dx-icon-insertrowabove,
.dx-swatch-additional .dx-icon-insertrowbelow,
.dx-swatch-additional .dx-icon-inserttable,
.dx-swatch-additional .dx-icon-isblank,
.dx-swatch-additional .dx-icon-isnotblank,
.dx-swatch-additional .dx-icon-italic,
.dx-swatch-additional .dx-icon-jpgfile,
.dx-swatch-additional .dx-icon-key,
.dx-swatch-additional .dx-icon-less,
.dx-swatch-additional .dx-icon-lessorequal,
.dx-swatch-additional .dx-icon-like,
.dx-swatch-additional .dx-icon-link,
.dx-swatch-additional .dx-icon-lock,
.dx-swatch-additional .dx-icon-login,
.dx-swatch-additional .dx-icon-map,
.dx-swatch-additional .dx-icon-mediumiconslayout,
.dx-swatch-additional .dx-icon-mention,
.dx-swatch-additional .dx-icon-menu,
.dx-swatch-additional .dx-icon-mergecells,
.dx-swatch-additional .dx-icon-message,
.dx-swatch-additional .dx-icon-minus,
.dx-swatch-additional .dx-icon-money,
.dx-swatch-additional .dx-icon-moon,
.dx-swatch-additional .dx-icon-more,
.dx-swatch-additional .dx-icon-movetofolder,
.dx-swatch-additional .dx-icon-music,
.dx-swatch-additional .dx-icon-newfolder,
.dx-swatch-additional .dx-icon-notequal,
.dx-swatch-additional .dx-icon-optionsgear,
.dx-swatch-additional .dx-icon-orderedlist,
.dx-swatch-additional .dx-icon-ordersbox,
.dx-swatch-additional .dx-icon-overflow,
.dx-swatch-additional .dx-icon-palette,
.dx-swatch-additional .dx-icon-panelleft,
.dx-swatch-additional .dx-icon-panelright,
.dx-swatch-additional .dx-icon-parentfolder,
.dx-swatch-additional .dx-icon-paste,
.dx-swatch-additional .dx-icon-pasteplaintext,
.dx-swatch-additional .dx-icon-pdffile,
.dx-swatch-additional .dx-icon-percent,
.dx-swatch-additional .dx-icon-photo,
.dx-swatch-additional .dx-icon-photooutline,
.dx-swatch-additional .dx-icon-pin,
.dx-swatch-additional .dx-icon-pinleft,
.dx-swatch-additional .dx-icon-pinmap,
.dx-swatch-additional .dx-icon-pinright,
.dx-swatch-additional .dx-icon-plus,
.dx-swatch-additional .dx-icon-pptfile,
.dx-swatch-additional .dx-icon-pptxfile,
.dx-swatch-additional .dx-icon-preferences,
.dx-swatch-additional .dx-icon-print,
.dx-swatch-additional .dx-icon-product,
.dx-swatch-additional .dx-icon-pulldown,
.dx-swatch-additional .dx-icon-range,
.dx-swatch-additional .dx-icon-redo,
.dx-swatch-additional .dx-icon-refresh,
.dx-swatch-additional .dx-icon-remove,
.dx-swatch-additional .dx-icon-rename,
.dx-swatch-additional .dx-icon-repeat,
.dx-swatch-additional .dx-icon-return,
.dx-swatch-additional .dx-icon-revert,
.dx-swatch-additional .dx-icon-rowfield,
.dx-swatch-additional .dx-icon-rowproperties,
.dx-swatch-additional .dx-icon-rtffile,
.dx-swatch-additional .dx-icon-runner,
.dx-swatch-additional .dx-icon-save,
.dx-swatch-additional .dx-icon-search,
.dx-swatch-additional .dx-icon-selectall,
.dx-swatch-additional .dx-icon-send,
.dx-swatch-additional .dx-icon-share,
.dx-swatch-additional .dx-icon-showpanel,
.dx-swatch-additional .dx-icon-shrinkfont,
.dx-swatch-additional .dx-icon-smalliconslayout,
.dx-swatch-additional .dx-icon-sortdown,
.dx-swatch-additional .dx-icon-sortdowntext,
.dx-swatch-additional .dx-icon-sorted,
.dx-swatch-additional .dx-icon-sortup,
.dx-swatch-additional .dx-icon-sortuptext,
.dx-swatch-additional .dx-icon-spindown,
.dx-swatch-additional .dx-icon-spinleft,
.dx-swatch-additional .dx-icon-spinnext,
.dx-swatch-additional .dx-icon-spinprev,
.dx-swatch-additional .dx-icon-spinright,
.dx-swatch-additional .dx-icon-spinup,
.dx-swatch-additional .dx-icon-splitcells,
.dx-swatch-additional .dx-icon-square,
.dx-swatch-additional .dx-icon-startswith,
.dx-swatch-additional .dx-icon-strike,
.dx-swatch-additional .dx-icon-subscript,
.dx-swatch-additional .dx-icon-sun,
.dx-swatch-additional .dx-icon-superscript,
.dx-swatch-additional .dx-icon-svgfile,
.dx-swatch-additional .dx-icon-tableproperties,
.dx-swatch-additional .dx-icon-tags,
.dx-swatch-additional .dx-icon-taskcomplete,
.dx-swatch-additional .dx-icon-taskhelpneeded,
.dx-swatch-additional .dx-icon-taskinprogress,
.dx-swatch-additional .dx-icon-taskrejected,
.dx-swatch-additional .dx-icon-taskstop,
.dx-swatch-additional .dx-icon-tel,
.dx-swatch-additional .dx-icon-textdocument,
.dx-swatch-additional .dx-icon-tips,
.dx-swatch-additional .dx-icon-to,
.dx-swatch-additional .dx-icon-todo,
.dx-swatch-additional .dx-icon-toolbox,
.dx-swatch-additional .dx-icon-trash,
.dx-swatch-additional .dx-icon-txtfile,
.dx-swatch-additional .dx-icon-underline,
.dx-swatch-additional .dx-icon-undo,
.dx-swatch-additional .dx-icon-unlock,
.dx-swatch-additional .dx-icon-unpin,
.dx-swatch-additional .dx-icon-unselectall,
.dx-swatch-additional .dx-icon-upload,
.dx-swatch-additional .dx-icon-user,
.dx-swatch-additional .dx-icon-variable,
.dx-swatch-additional .dx-icon-verticalalignbottom,
.dx-swatch-additional .dx-icon-verticalaligncenter,
.dx-swatch-additional .dx-icon-verticalaligntop,
.dx-swatch-additional .dx-icon-video,
.dx-swatch-additional .dx-icon-warning,
.dx-swatch-additional .dx-icon-xlsfile,
.dx-swatch-additional .dx-icon-xlsxfile {
  font: 14px/1 DXIcons;
}
.dx-swatch-additional .dx-icon-add:before {
  content: "\f00b";
}
.dx-swatch-additional .dx-icon-airplane:before {
  content: "\f000";
}
.dx-swatch-additional .dx-icon-bookmark:before {
  content: "\f017";
}
.dx-swatch-additional .dx-icon-box:before {
  content: "\f018";
}
.dx-swatch-additional .dx-icon-car:before {
  content: "\f01b";
}
.dx-swatch-additional .dx-icon-card:before {
  content: "\f019";
}
.dx-swatch-additional .dx-icon-cart:before {
  content: "\f01a";
}
.dx-swatch-additional .dx-icon-chart:before {
  content: "\f01c";
}
.dx-swatch-additional .dx-icon-check:before {
  content: "\f005";
}
.dx-swatch-additional .dx-icon-clear:before {
  content: "\f008";
}
.dx-swatch-additional .dx-icon-clock:before {
  content: "\f01d";
}
.dx-swatch-additional .dx-icon-close:before {
  content: "\f00a";
}
.dx-swatch-additional .dx-icon-coffee:before {
  content: "\f02a";
}
.dx-swatch-additional .dx-icon-comment:before {
  content: "\f01e";
}
.dx-swatch-additional .dx-icon-doc:before,
.dx-swatch-additional .dx-icon-file:before {
  content: "\f021";
}
.dx-swatch-additional .dx-icon-download:before {
  content: "\f022";
}
.dx-swatch-additional .dx-icon-dragvertical:before {
  content: "\f038";
}
.dx-swatch-additional .dx-icon-edit:before {
  content: "\f023";
}
.dx-swatch-additional .dx-icon-email:before {
  content: "\f024";
}
.dx-swatch-additional .dx-icon-event:before {
  content: "\f026";
}
.dx-swatch-additional .dx-icon-eventall:before {
  content: "\f043";
}
.dx-swatch-additional .dx-icon-favorites:before {
  content: "\f025";
}
.dx-swatch-additional .dx-icon-find:before {
  content: "\f027";
}
.dx-swatch-additional .dx-icon-filter:before {
  content: "\f050";
}
.dx-swatch-additional .dx-icon-activefolder:before,
.dx-swatch-additional .dx-icon-folder:before {
  content: "\f028";
}
.dx-swatch-additional .dx-icon-food:before {
  content: "\f029";
}
.dx-swatch-additional .dx-icon-gift:before {
  content: "\f02b";
}
.dx-swatch-additional .dx-icon-globe:before {
  content: "\f02c";
}
.dx-swatch-additional .dx-icon-group:before {
  content: "\f02e";
}
.dx-swatch-additional .dx-icon-help:before {
  content: "\f02f";
}
.dx-swatch-additional .dx-icon-home:before {
  content: "\f030";
}
.dx-swatch-additional .dx-icon-image:before {
  content: "\f031";
}
.dx-swatch-additional .dx-icon-info:before {
  content: "\f032";
}
.dx-swatch-additional .dx-icon-key:before {
  content: "\f033";
}
.dx-swatch-additional .dx-icon-like:before {
  content: "\f034";
}
.dx-swatch-additional .dx-icon-map:before {
  content: "\f035";
}
.dx-swatch-additional .dx-icon-menu:before {
  content: "\f00c";
}
.dx-swatch-additional .dx-icon-message:before {
  content: "\f024";
}
.dx-swatch-additional .dx-icon-money:before {
  content: "\f036";
}
.dx-swatch-additional .dx-icon-music:before {
  content: "\f037";
}
.dx-swatch-additional .dx-icon-overflow:before {
  content: "\f00d";
}
.dx-swatch-additional .dx-icon-percent:before {
  content: "\f039";
}
.dx-swatch-additional .dx-icon-photo:before {
  content: "\f03a";
}
.dx-swatch-additional .dx-icon-plus:before {
  content: "\f00b";
}
.dx-swatch-additional .dx-icon-minus:before {
  content: "\f074";
}
.dx-swatch-additional .dx-icon-preferences:before {
  content: "\f03b";
}
.dx-swatch-additional .dx-icon-product:before {
  content: "\f03c";
}
.dx-swatch-additional .dx-icon-pulldown:before {
  content: "\f062";
}
.dx-swatch-additional .dx-icon-refresh:before {
  content: "\f03d";
}
.dx-swatch-additional .dx-icon-remove:before {
  content: "\f00a";
}
.dx-swatch-additional .dx-icon-revert:before {
  content: "\f04c";
}
.dx-swatch-additional .dx-icon-runner:before {
  content: "\f040";
}
.dx-swatch-additional .dx-icon-save:before {
  content: "\f041";
}
.dx-swatch-additional .dx-icon-search:before {
  content: "\f027";
}
.dx-swatch-additional .dx-icon-tags:before {
  content: "\f009";
}
.dx-swatch-additional .dx-icon-tel:before {
  content: "\f003";
}
.dx-swatch-additional .dx-icon-tips:before {
  content: "\f004";
}
.dx-swatch-additional .dx-icon-todo:before {
  content: "\f005";
}
.dx-swatch-additional .dx-icon-toolbox:before {
  content: "\f007";
}
.dx-swatch-additional .dx-icon-trash:before {
  content: "\f03e";
}
.dx-swatch-additional .dx-icon-user:before {
  content: "\f02d";
}
.dx-swatch-additional .dx-icon-upload:before {
  content: "\f006";
}
.dx-swatch-additional .dx-icon-floppy:before {
  content: "\f073";
}
.dx-swatch-additional .dx-icon-arrowleft:before {
  content: "\f011";
}
.dx-swatch-additional .dx-icon-arrowdown:before {
  content: "\f015";
}
.dx-swatch-additional .dx-icon-arrowback:before {
  content: "\f15a";
}
.dx-swatch-additional .dx-icon-arrowright:before {
  content: "\f00e";
}
.dx-swatch-additional .dx-icon-arrowup:before {
  content: "\f013";
}
.dx-swatch-additional .dx-icon-spinleft:before,
.dx-swatch-additional .dx-icon-spinprev:before {
  content: "\f04f";
}
.dx-swatch-additional .dx-icon-spinnext:before,
.dx-swatch-additional .dx-icon-spinright:before {
  content: "\f04e";
}
.dx-swatch-additional .dx-icon-spindown:before {
  content: "\f001";
}
.dx-swatch-additional .dx-icon-spinup:before {
  content: "\f002";
}
.dx-swatch-additional .dx-icon-back:before,
.dx-swatch-additional .dx-icon-chevronleft:before,
.dx-swatch-additional .dx-icon-chevronprev:before {
  content: "\f012";
}
.dx-swatch-additional .dx-icon-chevronnext:before,
.dx-swatch-additional .dx-icon-chevronright:before {
  content: "\f010";
}
.dx-swatch-additional .dx-icon-chevrondown:before {
  content: "\f016";
}
.dx-swatch-additional .dx-icon-chevronup:before {
  content: "\f014";
}
.dx-swatch-additional .dx-icon-chevrondoubleleft:before {
  content: "\f042";
}
.dx-swatch-additional .dx-icon-chevrondoubleright:before {
  content: "\f03f";
}
.dx-swatch-additional .dx-icon-equal:before {
  content: "\f044";
}
.dx-swatch-additional .dx-icon-notequal:before {
  content: "\f045";
}
.dx-swatch-additional .dx-icon-less:before {
  content: "\f046";
}
.dx-swatch-additional .dx-icon-greater:before {
  content: "\f047";
}
.dx-swatch-additional .dx-icon-lessorequal:before {
  content: "\f048";
}
.dx-swatch-additional .dx-icon-greaterorequal:before {
  content: "\f049";
}
.dx-swatch-additional .dx-icon-isblank:before {
  content: "\f075";
}
.dx-swatch-additional .dx-icon-isnotblank:before {
  content: "\f076";
}
.dx-swatch-additional .dx-icon-sortup:before {
  content: "\f051";
}
.dx-swatch-additional .dx-icon-sortdown:before {
  content: "\f052";
}
.dx-swatch-additional .dx-icon-sortuptext:before {
  content: "\f053";
}
.dx-swatch-additional .dx-icon-sortdowntext:before {
  content: "\f054";
}
.dx-swatch-additional .dx-icon-sorted:before {
  content: "\f055";
}
.dx-swatch-additional .dx-icon-expand:before {
  content: "\f04a";
}
.dx-swatch-additional .dx-icon-collapse:before {
  content: "\f04b";
}
.dx-swatch-additional .dx-icon-columnfield:before {
  content: "\f057";
}
.dx-swatch-additional .dx-icon-rowfield:before {
  content: "\f058";
}
.dx-swatch-additional .dx-icon-datafield:before {
  content: "\f101";
}
.dx-swatch-additional .dx-icon-fields:before {
  content: "\f059";
}
.dx-swatch-additional .dx-icon-fieldchooser:before {
  content: "\f05a";
}
.dx-swatch-additional .dx-icon-columnchooser:before {
  content: "\f04d";
}
.dx-swatch-additional .dx-icon-pin:before {
  content: "\f05b";
}
.dx-swatch-additional .dx-icon-unpin:before {
  content: "\f05c";
}
.dx-swatch-additional .dx-icon-pinleft:before {
  content: "\f05d";
}
.dx-swatch-additional .dx-icon-pinright:before {
  content: "\f05e";
}
.dx-swatch-additional .dx-icon-contains:before {
  content: "\f063";
}
.dx-swatch-additional .dx-icon-startswith:before {
  content: "\f064";
}
.dx-swatch-additional .dx-icon-endswith:before {
  content: "\f065";
}
.dx-swatch-additional .dx-icon-doesnotcontain:before {
  content: "\f066";
}
.dx-swatch-additional .dx-icon-range:before {
  content: "\f06a";
}
.dx-swatch-additional .dx-icon-export:before {
  content: "\f05f";
}
.dx-swatch-additional .dx-icon-exportxlsx:before {
  content: "\f060";
}
.dx-swatch-additional .dx-icon-exportpdf:before {
  content: "\f061";
}
.dx-swatch-additional .dx-icon-exportselected:before {
  content: "\f06d";
}
.dx-swatch-additional .dx-icon-ordersbox:before {
  content: "\f06e";
}
.dx-swatch-additional .dx-icon-warning:before {
  content: "\f06b";
}
.dx-swatch-additional .dx-icon-taskhelpneeded:before {
  content: "\f06f";
}
.dx-swatch-additional .dx-icon-more:before {
  content: "\f06c";
}
.dx-swatch-additional .dx-icon-square:before {
  content: "\f067";
}
.dx-swatch-additional .dx-icon-clearsquare:before {
  content: "\f068";
}
.dx-swatch-additional .dx-icon-repeat:before {
  content: "\f069";
}
.dx-swatch-additional .dx-icon-selectall:before {
  content: "\f070";
}
.dx-swatch-additional .dx-icon-unselectall:before {
  content: "\f071";
}
.dx-swatch-additional .dx-icon-print:before {
  content: "\f072";
}
.dx-swatch-additional .dx-icon-bold:before {
  content: "\f077";
}
.dx-swatch-additional .dx-icon-italic:before {
  content: "\f078";
}
.dx-swatch-additional .dx-icon-underline:before {
  content: "\f079";
}
.dx-swatch-additional .dx-icon-strike:before {
  content: "\f07a";
}
.dx-swatch-additional .dx-icon-increaselinespacing:before,
.dx-swatch-additional .dx-icon-indent:before {
  content: "\f07b";
}
.dx-swatch-additional .dx-icon-font:before {
  content: "\f11b";
}
.dx-swatch-additional .dx-icon-fontsize:before {
  content: "\f07c";
}
.dx-swatch-additional .dx-icon-shrinkfont:before {
  content: "\f07d";
}
.dx-swatch-additional .dx-icon-growfont:before {
  content: "\f07e";
}
.dx-swatch-additional .dx-icon-color:before {
  content: "\f07f";
}
.dx-swatch-additional .dx-icon-background:before {
  content: "\f080";
}
.dx-swatch-additional .dx-icon-fill:before {
  content: "\f10d";
}
.dx-swatch-additional .dx-icon-palette:before {
  content: "\f120";
}
.dx-swatch-additional .dx-icon-superscript:before {
  content: "\f081";
}
.dx-swatch-additional .dx-icon-subscript:before {
  content: "\f082";
}
.dx-swatch-additional .dx-icon-header:before {
  content: "\f083";
}
.dx-swatch-additional .dx-icon-blockquote:before {
  content: "\f084";
}
.dx-swatch-additional .dx-icon-formula:before {
  content: "\f056";
}
.dx-swatch-additional .dx-icon-codeblock:before {
  content: "\f085";
}
.dx-swatch-additional .dx-icon-orderedlist:before {
  content: "\f086";
}
.dx-swatch-additional .dx-icon-bulletlist:before {
  content: "\f087";
}
.dx-swatch-additional .dx-icon-increaseindent:before {
  content: "\f088";
}
.dx-swatch-additional .dx-icon-decreaseindent:before {
  content: "\f089";
}
.dx-swatch-additional .dx-icon-decreaselinespacing:before {
  content: "\f106";
}
.dx-swatch-additional .dx-icon-alignleft:before {
  content: "\f08a";
}
.dx-swatch-additional .dx-icon-alignright:before {
  content: "\f08b";
}
.dx-swatch-additional .dx-icon-aligncenter:before {
  content: "\f08c";
}
.dx-swatch-additional .dx-icon-alignjustify:before {
  content: "\f08d";
}
.dx-swatch-additional .dx-icon-link:before {
  content: "\f08e";
}
.dx-swatch-additional .dx-icon-video:before {
  content: "\f08f";
}
.dx-swatch-additional .dx-icon-mention:before {
  content: "\f090";
}
.dx-swatch-additional .dx-icon-variable:before {
  content: "\f091";
}
.dx-swatch-additional .dx-icon-clearformat:before {
  content: "\f092";
}
.dx-swatch-additional .dx-icon-accountbox:before {
  content: "\f094";
}
.dx-swatch-additional .dx-icon-fullscreen:before {
  content: "\f11a";
}
.dx-swatch-additional .dx-icon-hierarchy:before {
  content: "\f124";
}
.dx-swatch-additional .dx-icon-docfile:before {
  content: "\f111";
}
.dx-swatch-additional .dx-icon-docxfile:before {
  content: "\f110";
}
.dx-swatch-additional .dx-icon-pdffile:before {
  content: "\f118";
}
.dx-swatch-additional .dx-icon-pptfile:before {
  content: "\f114";
}
.dx-swatch-additional .dx-icon-pptxfile:before {
  content: "\f115";
}
.dx-swatch-additional .dx-icon-rtffile:before {
  content: "\f112";
}
.dx-swatch-additional .dx-icon-txtfile:before {
  content: "\f113";
}
.dx-swatch-additional .dx-icon-xlsfile:before {
  content: "\f116";
}
.dx-swatch-additional .dx-icon-xlsxfile:before {
  content: "\f117";
}
.dx-swatch-additional .dx-icon-copy:before {
  content: "\f107";
}
.dx-swatch-additional .dx-icon-cut:before {
  content: "\f10a";
}
.dx-swatch-additional .dx-icon-paste:before {
  content: "\f108";
}
.dx-swatch-additional .dx-icon-share:before {
  content: "\f11f";
}
.dx-swatch-additional .dx-icon-inactivefolder:before {
  content: "\f105";
}
.dx-swatch-additional .dx-icon-newfolder:before {
  content: "\f123";
}
.dx-swatch-additional .dx-icon-movetofolder:before {
  content: "\f121";
}
.dx-swatch-additional .dx-icon-parentfolder:before {
  content: "\f122";
}
.dx-swatch-additional .dx-icon-rename:before {
  content: "\f109";
}
.dx-swatch-additional .dx-icon-detailslayout:before {
  content: "\f10b";
}
.dx-swatch-additional .dx-icon-contentlayout:before {
  content: "\f11e";
}
.dx-swatch-additional .dx-icon-smalliconslayout:before {
  content: "\f119";
}
.dx-swatch-additional .dx-icon-mediumiconslayout:before {
  content: "\f10c";
}
.dx-swatch-additional .dx-icon-undo:before {
  content: "\f04c";
}
.dx-swatch-additional .dx-icon-redo:before {
  content: "\f093";
}
.dx-swatch-additional .dx-icon-hidepanel:before {
  content: "\f11c";
}
.dx-swatch-additional .dx-icon-showpanel:before {
  content: "\f11d";
}
.dx-swatch-additional .dx-icon-checklist:before {
  content: "\f141";
}
.dx-swatch-additional .dx-icon-verticalaligntop:before {
  content: "\f14f";
}
.dx-swatch-additional .dx-icon-verticalaligncenter:before {
  content: "\f14e";
}
.dx-swatch-additional .dx-icon-verticalalignbottom:before {
  content: "\f14d";
}
.dx-swatch-additional .dx-icon-rowproperties:before {
  content: "\f14c";
}
.dx-swatch-additional .dx-icon-columnproperties:before {
  content: "\f14b";
}
.dx-swatch-additional .dx-icon-cellproperties:before {
  content: "\f14a";
}
.dx-swatch-additional .dx-icon-tableproperties:before {
  content: "\f140";
}
.dx-swatch-additional .dx-icon-splitcells:before {
  content: "\f139";
}
.dx-swatch-additional .dx-icon-mergecells:before {
  content: "\f138";
}
.dx-swatch-additional .dx-icon-deleterow:before {
  content: "\f137";
}
.dx-swatch-additional .dx-icon-deletecolumn:before {
  content: "\f136";
}
.dx-swatch-additional .dx-icon-insertrowabove:before {
  content: "\f135";
}
.dx-swatch-additional .dx-icon-insertrowbelow:before {
  content: "\f134";
}
.dx-swatch-additional .dx-icon-insertcolumnleft:before {
  content: "\f133";
}
.dx-swatch-additional .dx-icon-insertcolumnright:before {
  content: "\f132";
}
.dx-swatch-additional .dx-icon-inserttable:before {
  content: "\f130";
}
.dx-swatch-additional .dx-icon-deletetable:before {
  content: "\f131";
}
.dx-swatch-additional .dx-icon-edittableheader:before {
  content: "\f142";
}
.dx-swatch-additional .dx-icon-addtableheader:before {
  content: "\f143";
}
.dx-swatch-additional .dx-icon-pasteplaintext:before {
  content: "\f144";
}
.dx-swatch-additional .dx-icon-importselected:before {
  content: "\f145";
}
.dx-swatch-additional .dx-icon-import:before {
  content: "\f146";
}
.dx-swatch-additional .dx-icon-textdocument:before {
  content: "\f147";
}
.dx-swatch-additional .dx-icon-jpgfile:before {
  content: "\f148";
}
.dx-swatch-additional .dx-icon-bmpfile:before {
  content: "\f149";
}
.dx-swatch-additional .dx-icon-svgfile:before {
  content: "\f150";
}
.dx-swatch-additional .dx-icon-attach:before {
  content: "\f151";
}
.dx-swatch-additional .dx-icon-return:before {
  content: "\f152";
}
.dx-swatch-additional .dx-icon-indeterminatestate:before {
  content: "\f153";
}
.dx-swatch-additional .dx-icon-lock:before {
  content: "\f154";
}
.dx-swatch-additional .dx-icon-unlock:before {
  content: "\f155";
}
.dx-swatch-additional .dx-icon-imgarlock:before {
  content: "\f156";
}
.dx-swatch-additional .dx-icon-imgarunlock:before {
  content: "\f157";
}
.dx-swatch-additional .dx-icon-bell:before {
  content: "\f158";
}
.dx-swatch-additional .dx-icon-sun:before {
  content: "\f159";
}
.dx-swatch-additional .dx-icon-taskcomplete:before {
  content: "\f15b";
}
.dx-swatch-additional .dx-icon-taskrejected:before {
  content: "\f15c";
}
.dx-swatch-additional .dx-icon-taskinprogress:before {
  content: "\f15d";
}
.dx-swatch-additional .dx-icon-taskstop:before {
  content: "\f15e";
}
.dx-swatch-additional .dx-icon-clearcircle:before {
  content: "\f15f";
}
.dx-swatch-additional .dx-icon-send:before {
  content: "\f160";
}
.dx-swatch-additional .dx-icon-pinmap:before {
  content: "\f161";
}
.dx-swatch-additional .dx-icon-photooutline:before {
  content: "\f162";
}
.dx-swatch-additional .dx-icon-panelright:before {
  content: "\f163";
}
.dx-swatch-additional .dx-icon-panelleft:before {
  content: "\f164";
}
.dx-swatch-additional .dx-icon-optionsgear:before {
  content: "\f165";
}
.dx-swatch-additional .dx-icon-moon:before {
  content: "\f166";
}
.dx-swatch-additional .dx-icon-login:before {
  content: "\f167";
}
.dx-swatch-additional .dx-icon-eyeopen:before {
  content: "\f168";
}
.dx-swatch-additional .dx-icon-eyeclose:before {
  content: "\f169";
}
.dx-swatch-additional .dx-icon-expandform:before {
  content: "\f170";
}
.dx-swatch-additional .dx-icon-description:before {
  content: "\f171";
}
.dx-swatch-additional .dx-icon-belloutline:before {
  content: "\f172";
}
.dx-swatch-additional .dx-icon-to:before {
  content: "\f173";
}
.dx-swatch-additional .dx-icon-errorcircle:before {
  content: "\f174";
}
.dx-swatch-additional .dx-icon-datatrending:before {
  content: "\f175";
}
.dx-swatch-additional .dx-icon-dataarea:before {
  content: "\f176";
}
.dx-swatch-additional .dx-icon-datausage:before {
  content: "\f177";
}
.dx-swatch-additional .dx-icon-datapie:before {
  content: "\f178";
}
.dx-swatch-additional .dx-tab .dx-icon,
.dx-swatch-additional .dx-tab.dx-tab-selected .dx-icon {
  background-size: 100% 100%;
  background-position: 50% 50%;
}
.dx-swatch-additional .dx-scrollview-pulldown {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAyCAQAAAD2dTOtAAAApElEQVRIx2OYxQCHvlggw38oZBgcCrFCihQ2YIFDViH1g+cMFjhkFVI/eP5jgUNWIfWDh2iFdbMuYUCsCmcS65lRhXRRqDXrwKzDYPgci8LDUFgKMilq1h+saREBt8xig1gai1fp9llsCDcm4FS2dxYnqmfS8ClD9XUmFq9wYk/hOWjKeHBnhVK4smMgZfjyTDVY2clZ/IQzVzWwuOPHyFz/iYQAhMbywidpE3YAAAAASUVORK5CYII=");
  background-position: 0 0;
  background-repeat: no-repeat;
}
.dx-swatch-additional .dx-loadindicator-image,
.dx-swatch-additional .dx-loadindicator-image-large,
.dx-swatch-additional .dx-loadindicator-image-small {
  background-position: center center;
  background-repeat: no-repeat;
}
.dx-swatch-additional .dx-color-scheme {
  font-family: "#";
}
.dx-swatch-additional .dx-widget {
  display: block;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;
  -webkit-touch-callout: none;
  padding: 0;
  outline: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  font-family: "Helvetica Neue","Segoe UI",helvetica,verdana,sans-serif;
  line-height: 1.35715;
}
.dx-swatch-additional .dx-widget,
.dx-swatch-additional .dx-widget *,
.dx-swatch-additional .dx-widget ::after,
.dx-swatch-additional .dx-widget ::before,
.dx-swatch-additional .dx-widget::after,
.dx-swatch-additional .dx-widget::before {
  box-sizing: border-box;
}
.dx-swatch-additional .dx-item {
  outline: 0;
}
.dx-swatch-additional .dx-rtl {
  direction: rtl;
  unicode-bidi: embed;
}
.dx-swatch-additional .dx-state-disabled {
  pointer-events: none;
}
.dx-swatch-additional .dx-widget input,
.dx-swatch-additional .dx-widget textarea {
  font-family: "Helvetica Neue","Segoe UI",helvetica,verdana,sans-serif;
  line-height: 1.35715;
}
.dx-swatch-additional .dx-state-disabled .dx-widget,
.dx-swatch-additional .dx-state-disabled.dx-widget {
  opacity: .5;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  cursor: default;
}
.dx-swatch-additional .dx-state-disabled .dx-widget .dx-widget,
.dx-swatch-additional .dx-state-disabled.dx-widget .dx-widget {
  opacity: 1;
}
.dx-swatch-additional .dx-surface {
  background-color: #2a2a2a;
}
.dx-swatch-additional .dx-card {
  overflow: hidden;
  border: 1px solid #4d4d4d;
  border-radius: 4px;
  background-color: #2a2a2a;
}
.dx-swatch-additional .dx-fieldset .dx-field-value {
  margin: 0;
}
.dx-swatch-additional .dx-fieldset,
.dx-swatch-additional .dx-fieldset * {
  box-sizing: border-box;
}
.dx-swatch-additional .dx-fieldset-header:empty {
  display: none;
}
.dx-swatch-additional .dx-field::after,
.dx-swatch-additional .dx-field::before {
  display: table;
  content: "";
  line-height: 0;
}
.dx-swatch-additional .dx-field::after {
  clear: both;
}
.dx-swatch-additional .dx-field-label {
  float: left;
  width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
  cursor: default;
  padding: 8px 15px 9px 0;
}
.dx-swatch-additional .dx-field-value,
.dx-swatch-additional .dx-field-value-static {
  float: right;
}
.dx-swatch-additional .dx-field-value.dx-datebox {
  min-width: 60%;
}
.dx-swatch-additional .dx-field-value:not(.dx-widget) > .dx-datebox {
  min-width: 100%;
}
.dx-swatch-additional .dx-field-value .dx-selectbox-tag-container {
  white-space: normal;
}
.dx-swatch-additional .dx-field-value:not(.dx-widget) > .dx-selectbox.dx-selectbox-multiselect.dx-widget {
  position: relative;
  width: auto;
  text-align: left;
}
.dx-swatch-additional .dx-fieldset.dx-rtl .dx-field-label,
.dx-swatch-additional .dx-rtl .dx-fieldset .dx-field-label {
  float: right;
}
.dx-swatch-additional .dx-fieldset.dx-rtl .dx-field-value,
.dx-swatch-additional .dx-rtl .dx-fieldset .dx-field-value {
  float: left;
}
.dx-swatch-additional .dx-field {
  position: relative;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  font-family: "Helvetica Neue","Segoe UI",helvetica,verdana,sans-serif;
  line-height: 1.35715;
  min-height: 36px;
  padding: 0;
}
.dx-swatch-additional .dx-field input,
.dx-swatch-additional .dx-field textarea {
  font-family: "Helvetica Neue","Segoe UI",helvetica,verdana,sans-serif;
  line-height: 1.35715;
}
.dx-swatch-additional .dx-field-value.dx-attention {
  color: #d9534f;
  padding: 8px 10px 9px 28px;
  position: relative;
}
.dx-swatch-additional .dx-field-value.dx-attention::before {
  pointer-events: none;
  font-weight: 700;
  position: absolute;
  top: 50%;
  margin-top: -9px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  font-size: 13px;
  background-color: #d9534f;
  color: #2a2a2a;
  content: "!";
  border-radius: 50%;
  left: 0;
}
.dx-swatch-additional .dx-field-value-static,
.dx-swatch-additional .dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
  width: 60%;
}
.dx-swatch-additional .dx-field-value.dx-widget,
.dx-swatch-additional .dx-field-value:not(.dx-widget) > .dx-widget {
  margin: 0;
}
.dx-swatch-additional .dx-field-value:not(.dx-widget) > .dx-button,
.dx-swatch-additional .dx-field-value:not(.dx-widget) > .dx-checkbox,
.dx-swatch-additional .dx-field-value:not(.dx-widget) > .dx-switch {
  float: right;
}
.dx-swatch-additional .dx-field-value.dx-checkbox,
.dx-swatch-additional .dx-field-value:not(.dx-widget) > .dx-checkbox {
  margin: 7px 0;
}
.dx-swatch-additional .dx-field-value.dx-switch,
.dx-swatch-additional .dx-field-value:not(.dx-widget) > .dx-switch {
  margin: 6px 0;
}
.dx-swatch-additional .dx-field-value.dx-slider,
.dx-swatch-additional .dx-field-value:not(.dx-widget) > .dx-slider {
  margin: 4px 0;
}
.dx-swatch-additional .dx-field-value.dx-radiogroup,
.dx-swatch-additional .dx-field-value:not(.dx-widget) > .dx-radiogroup {
  margin: 5px 0;
}
.dx-swatch-additional .dx-field-value-static {
  white-space: normal;
  padding: 8px 10px 9px;
}
.dx-swatch-additional .dx-fieldset {
  margin: 30px 20px;
  padding: 0;
}
.dx-swatch-additional .dx-rtl .dx-fieldset .dx-field-value:not(.dx-widget) > .dx-button,
.dx-swatch-additional .dx-rtl .dx-fieldset .dx-field-value:not(.dx-widget) > .dx-checkbox,
.dx-swatch-additional .dx-rtl .dx-fieldset .dx-field-value:not(.dx-widget) > .dx-switch,
.dx-swatch-additional .dx-rtl.dx-fieldset .dx-field-value:not(.dx-widget) > .dx-button,
.dx-swatch-additional .dx-rtl.dx-fieldset .dx-field-value:not(.dx-widget) > .dx-checkbox,
.dx-swatch-additional .dx-rtl.dx-fieldset .dx-field-value:not(.dx-widget) > .dx-switch {
  float: left;
}
.dx-swatch-additional .dx-fieldset-header {
  margin: 0 0 20px;
  font-weight: 500;
  font-size: 18px;
}
.dx-swatch-additional .dx-field {
  margin: 0 0 10px;
}
.dx-swatch-additional .dx-field:last-of-type {
  margin: 0;
}
.dx-swatch-additional .dx-theme-marker {
  font-family: "dx.generic.dark";
}
.dx-swatch-additional .dx-theme-accent-as-text-color {
  color: #337ab7!important;
}
.dx-swatch-additional .dx-theme-text-color {
  color: #fff!important;
}
.dx-swatch-additional .dx-theme-background-color-as-text-color {
  color: #2a2a2a!important;
}
.dx-swatch-additional .dx-theme-border-color-as-text-color {
  color: #4d4d4d!important;
}
.dx-swatch-additional .dx-theme-accent-as-background-color {
  background-color: #337ab7!important;
  fill: #337ab7!important;
}
.dx-swatch-additional .dx-theme-text-color-as-background-color {
  background-color: #fff!important;
  fill: white!important;
}
.dx-swatch-additional .dx-theme-background-color {
  background-color: #2a2a2a!important;
  fill: #2a2a2a!important;
}
.dx-swatch-additional .dx-theme-typography-background-color {
  background-color: #2a2a2a!important;
}
.dx-swatch-additional .dx-theme-border-color-as-background-color {
  background-color: #4d4d4d!important;
  fill: #4d4d4d!important;
}
.dx-swatch-additional .dx-theme-accent-as-border-color {
  border-color: #337ab7!important;
}
.dx-swatch-additional .dx-theme-text-color-as-border-color {
  border-color: #fff!important;
}
.dx-swatch-additional .dx-theme-background-color-as-border-color {
  border-color: #2a2a2a!important;
}
.dx-swatch-additional .dx-theme-border-color {
  border-color: #4d4d4d!important;
}
.dx-swatch-additional .dx-resizable {
  display: block;
  position: relative;
}
.dx-swatch-additional .dx-resizable-handle {
  position: absolute;
  z-index: 50;
}
.dx-swatch-additional .dx-state-disabled .dx-resizable-handle {
  cursor: default;
}
.dx-swatch-additional .dx-resizable-handle-left,
.dx-swatch-additional .dx-resizable-handle-right {
  top: 0;
  height: 100%;
  width: 3px;
}
.dx-swatch-additional .dx-resizable-handle-left {
  left: 0;
  cursor: e-resize;
}
.dx-swatch-additional .dx-resizable-handle-right {
  right: 0;
  cursor: e-resize;
}
.dx-swatch-additional .dx-resizable-handle-bottom,
.dx-swatch-additional .dx-resizable-handle-top {
  left: 0;
  width: 100%;
  height: 3px;
}
.dx-swatch-additional .dx-resizable-handle-top {
  top: 0;
  cursor: s-resize;
}
.dx-swatch-additional .dx-resizable-handle-bottom {
  bottom: 0;
  cursor: s-resize;
}
.dx-swatch-additional .dx-resizable-handle-corner-bottom-left,
.dx-swatch-additional .dx-resizable-handle-corner-bottom-right,
.dx-swatch-additional .dx-resizable-handle-corner-top-left,
.dx-swatch-additional .dx-resizable-handle-corner-top-right {
  width: 6px;
  height: 6px;
}
.dx-swatch-additional .dx-resizable-handle-corner-bottom-right,
.dx-swatch-additional .dx-resizable-handle-corner-top-left {
  cursor: se-resize;
}
.dx-swatch-additional .dx-resizable-handle-corner-bottom-left,
.dx-swatch-additional .dx-resizable-handle-corner-top-right {
  cursor: ne-resize;
}
.dx-swatch-additional .dx-resizable-handle-corner-bottom-left,
.dx-swatch-additional .dx-resizable-handle-corner-top-left {
  left: 0;
  border-bottom-right-radius: 100%;
}
.dx-swatch-additional .dx-resizable-handle-corner-bottom-right,
.dx-swatch-additional .dx-resizable-handle-corner-top-right {
  right: 0;
  border-bottom-left-radius: 100%;
}
.dx-swatch-additional .dx-resizable-handle-corner-top-left,
.dx-swatch-additional .dx-resizable-handle-corner-top-right {
  top: 0;
}
.dx-swatch-additional .dx-resizable-handle-corner-bottom-left,
.dx-swatch-additional .dx-resizable-handle-corner-bottom-right {
  bottom: 0;
}
.dx-swatch-additional .dx-draggable {
  left: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}
.dx-swatch-additional .dx-draggable.dx-state-disabled,
.dx-swatch-additional .dx-state-disabled .dx-draggable {
  cursor: default;
}
.dx-swatch-additional .dx-draggable-clone {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2147483647;
}
.dx-swatch-additional .dx-clearfix::after,
.dx-swatch-additional .dx-clearfix::before {
  display: table;
  content: "";
  line-height: 0;
}
.dx-swatch-additional .dx-clearfix::after {
  clear: both;
}
.dx-swatch-additional .dx-translate-disabled {
  transform: none!important;
}
.dx-swatch-additional .dx-hidden-input {
  position: fixed;
  top: -10px;
  left: -10px;
  width: 0;
  height: 0;
}
.dx-swatch-additional .dx-user-select {
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text;
}
.dx-swatch-additional .dx-hidden,
.dx-swatch-additional .dx-state-invisible {
  display: none!important;
}
.dx-swatch-additional .dx-gesture-cover {
  transform: translate3d(0,0,0);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  z-index: 2147483647;
}
.dx-swatch-additional .dx-animating {
  pointer-events: none;
}
.dx-swatch-additional .dx-fade-animation.dx-enter,
.dx-swatch-additional .dx-fade-animation.dx-leave.dx-leave-active,
.dx-swatch-additional .dx-no-direction.dx-enter,
.dx-swatch-additional .dx-no-direction.dx-leave.dx-leave-active {
  opacity: 0;
}
.dx-swatch-additional .dx-fade-animation.dx-enter.dx-enter-active,
.dx-swatch-additional .dx-fade-animation.dx-leave,
.dx-swatch-additional .dx-no-direction.dx-enter.dx-enter-active,
.dx-swatch-additional .dx-no-direction.dx-leave {
  opacity: 1;
}
.dx-swatch-additional .dx-overflow-animation.dx-enter.dx-forward {
  transform: translate3d(100%,0,0);
  z-index: 2;
}
.dx-swatch-additional .dx-overflow-animation.dx-enter.dx-enter-active.dx-forward {
  transform: translate3d(0,0,0);
  z-index: 2;
}
.dx-swatch-additional .dx-overflow-animation.dx-enter.dx-backward,
.dx-swatch-additional .dx-overflow-animation.dx-enter.dx-enter-active.dx-backward,
.dx-swatch-additional .dx-overflow-animation.dx-leave.dx-forward,
.dx-swatch-additional .dx-overflow-animation.dx-leave.dx-leave-active.dx-forward {
  transform: translate3d(0,0,0);
  z-index: 1;
}
.dx-swatch-additional .dx-overflow-animation.dx-leave.dx-backward {
  transform: translate3d(0,0,0);
  z-index: 2;
}
.dx-swatch-additional .dx-overflow-animation.dx-leave.dx-leave-active.dx-backward {
  transform: translate3d(100%,0,0);
  z-index: 2;
}
.dx-swatch-additional .dx-slide-animation.dx-enter.dx-forward {
  transform: translate3d(100%,0,0);
}
.dx-swatch-additional .dx-slide-animation.dx-enter.dx-enter-active.dx-forward {
  transform: translate3d(0,0,0);
}
.dx-swatch-additional .dx-slide-animation.dx-enter.dx-backward {
  transform: translate3d(-100%,0,0);
}
.dx-swatch-additional .dx-slide-animation.dx-enter.dx-enter-active.dx-backward,
.dx-swatch-additional .dx-slide-animation.dx-leave.dx-forward {
  transform: translate3d(0,0,0);
}
.dx-swatch-additional .dx-slide-animation.dx-leave.dx-leave-active.dx-forward {
  transform: translate3d(-100%,0,0);
}
.dx-swatch-additional .dx-slide-animation.dx-leave.dx-backward {
  transform: translate3d(0,0,0);
}
.dx-swatch-additional .dx-slide-animation.dx-leave.dx-leave-active.dx-backward {
  transform: translate3d(100%,0,0);
}
.dx-swatch-additional .dx-opendoor-animation.dx-enter.dx-forward {
  transform: matrix3d(.71,0,.71,.001,0,1,0,0,-.71,0,.71,0,0,0,0,1);
  transform-origin: center left 0;
  opacity: 0;
}
.dx-swatch-additional .dx-opendoor-animation.dx-enter.dx-enter-active.dx-backward,
.dx-swatch-additional .dx-opendoor-animation.dx-enter.dx-enter-active.dx-forward {
  transform: none;
  opacity: 1;
}
.dx-swatch-additional .dx-opendoor-animation.dx-leave.dx-backward,
.dx-swatch-additional .dx-opendoor-animation.dx-leave.dx-forward {
  transform: none;
  transform-origin: center left 0;
  opacity: 1;
}
.dx-swatch-additional .dx-opendoor-animation.dx-enter.dx-backward,
.dx-swatch-additional .dx-opendoor-animation.dx-leave.dx-leave-active.dx-forward {
  transform: matrix3d(.5,0,.87,-.001,0,1,0,0,-.87,0,.5,0,0,0,0,1);
  transform-origin: center left 0;
  opacity: 0;
}
.dx-swatch-additional .dx-opendoor-animation.dx-leave.dx-leave-active.dx-backward {
  transform: matrix3d(.71,0,.71,.001,0,1,0,0,-.71,0,.71,0,0,0,0,1);
  opacity: 0;
}
.dx-swatch-additional .dx-win-pop-animation.dx-enter.dx-forward {
  transform: scale(.5);
  opacity: 0;
}
.dx-swatch-additional .dx-win-pop-animation.dx-enter.dx-enter-active.dx-forward {
  transform: scale(1);
  opacity: 1;
}
.dx-swatch-additional .dx-win-pop-animation.dx-enter.dx-backward,
.dx-swatch-additional .dx-win-pop-animation.dx-leave.dx-leave-active.dx-forward {
  transform: scale(1.5);
  opacity: 0;
}
.dx-swatch-additional .dx-win-pop-animation.dx-enter.dx-enter-active.dx-backward {
  transform: scale(1);
  opacity: 1;
}
.dx-swatch-additional .dx-win-pop-animation.dx-leave.dx-leave-active.dx-backward {
  transform: scale(.5);
  opacity: 0;
}
.dx-swatch-additional .dx-android-pop-animation.dx-enter.dx-forward,
.dx-swatch-additional .dx-android-pop-animation.dx-leave.dx-leave-active.dx-backward {
  transform: translate3d(0,150px,0);
  opacity: 0;
}
.dx-swatch-additional .dx-android-pop-animation.dx-enter.dx-enter-active.dx-forward,
.dx-swatch-additional .dx-android-pop-animation.dx-leave.dx-backward {
  transform: translate3d(0,0,0);
  opacity: 1;
}
.dx-swatch-additional .dx-android-pop-animation.dx-enter.dx-forward,
.dx-swatch-additional .dx-android-pop-animation.dx-leave.dx-backward {
  z-index: 1;
}
.dx-swatch-additional .dx-ios7-slide-animation.dx-enter.dx-forward {
  z-index: 2;
  transform: translate3d(100%,0,0);
}
.dx-swatch-additional .dx-ios7-slide-animation.dx-enter.dx-enter-active.dx-forward {
  z-index: 2;
  transform: translate3d(0,0,0);
}
.dx-swatch-additional .dx-ios7-slide-animation.dx-enter.dx-backward {
  transform: translate3d(-20%,0,0);
  z-index: 1;
}
.dx-swatch-additional .dx-ios7-slide-animation.dx-enter.dx-enter-active.dx-backward {
  transform: translate3d(0,0,0);
  z-index: 1;
}
.dx-swatch-additional .dx-ios7-slide-animation.dx-leave.dx-forward {
  z-index: 1;
  transform: translate3d(0,0,0);
}
.dx-swatch-additional .dx-ios7-slide-animation.dx-leave.dx-leave-active.dx-forward {
  transform: translate3d(-20%,0,0);
  z-index: 1;
}
.dx-swatch-additional .dx-ios7-slide-animation.dx-leave.dx-backward {
  z-index: 2;
}
.dx-swatch-additional .dx-ios7-slide-animation.dx-leave.dx-leave-active.dx-backward {
  transform: translate3d(100%,0,0);
  z-index: 2;
}
.dx-swatch-additional .dx-ios7-toolbar-animation.dx-enter.dx-forward {
  transform: translate3d(40%,0,0);
  opacity: 0;
  z-index: 2;
}
.dx-swatch-additional .dx-ios7-toolbar-animation.dx-enter.dx-enter-active.dx-forward {
  transform: translate3d(0,0,0);
  opacity: 1;
  z-index: 2;
}
.dx-swatch-additional .dx-ios7-toolbar-animation.dx-enter.dx-backward {
  transform: translate3d(-40%,0,0);
  opacity: 0;
  z-index: 1;
}
.dx-swatch-additional .dx-ios7-toolbar-animation.dx-enter.dx-enter-active.dx-backward,
.dx-swatch-additional .dx-ios7-toolbar-animation.dx-leave.dx-forward {
  transform: translate3d(0,0,0);
  opacity: 1;
  z-index: 1;
}
.dx-swatch-additional .dx-ios7-toolbar-animation.dx-leave.dx-leave-active.dx-forward {
  transform: translate3d(-40%,0,0);
  opacity: 0;
  z-index: 1;
}
.dx-swatch-additional .dx-ios7-toolbar-animation.dx-leave.dx-backward {
  transform: translate3d(0,0,0);
  opacity: 1;
  z-index: 2;
}
.dx-swatch-additional .dx-ios7-toolbar-animation.dx-leave.dx-leave-active.dx-backward {
  transform: translate3d(40%,0,0);
  opacity: 0;
  z-index: 2;
}
.dx-swatch-additional .dx-drop-animation.dx-enter,
.dx-swatch-additional .dx-drop-animation.dx-leave.dx-leave-active {
  transform: translate3d(0,-120%,0);
}
.dx-swatch-additional .dx-drop-animation.dx-enter.dx-enter-active,
.dx-swatch-additional .dx-drop-animation.dx-leave {
  transform: translate3d(0,0,0);
}
.dx-swatch-additional .dx-3d-drop-animation.dx-enter,
.dx-swatch-additional .dx-3d-drop-animation.dx-leave.dx-leave-active {
  transform: rotate3d(1,0,0,10deg) translate3d(0,-10px,0) scale3d(1.1,1.1,1.1);
  opacity: 0;
}
.dx-swatch-additional .dx-3d-drop-animation.dx-enter.dx-enter-active,
.dx-swatch-additional .dx-3d-drop-animation.dx-leave {
  transform: rotate3d(1,0,0,0) translate3d(0,0,0) scale3d(1,1,1);
  opacity: 1;
}
.dx-swatch-additional .dx-fade-drop-animation.dx-enter,
.dx-swatch-additional .dx-fade-drop-animation.dx-leave.dx-leave-active {
  transform: translate3d(0,-10px,0) scale3d(1.1,1.1,1.1);
  opacity: 0;
}
.dx-swatch-additional .dx-fade-drop-animation.dx-enter.dx-enter-active,
.dx-swatch-additional .dx-fade-drop-animation.dx-leave {
  transform: translate3d(0,0,0) scale3d(1,1,1);
  opacity: 1;
}
.dx-swatch-additional .dx-fade-rise-animation.dx-enter,
.dx-swatch-additional .dx-fade-rise-animation.dx-leave.dx-leave-active {
  transform: translate3d(0,10px,0) scale3d(1.1,1.1,1.1);
  opacity: 0;
}
.dx-swatch-additional .dx-fade-rise-animation.dx-enter.dx-enter-active,
.dx-swatch-additional .dx-fade-rise-animation.dx-leave {
  transform: translate3d(0,0,0) scale3d(1,1,1);
  opacity: 1;
}
.dx-swatch-additional .dx-fade-slide-animation.dx-enter,
.dx-swatch-additional .dx-fade-slide-animation.dx-leave.dx-leave-active {
  transform: translate3d(40%,0,0);
  opacity: 0;
}
.dx-swatch-additional .dx-fade-slide-animation.dx-enter.dx-enter-active,
.dx-swatch-additional .dx-fade-slide-animation.dx-leave {
  transform: translate3d(0,0,0);
  opacity: 1;
}
.dx-swatch-additional .dx-fade-zoom-animation.dx-enter,
.dx-swatch-additional .dx-fade-zoom-animation.dx-leave.dx-leave-active {
  transform: scale3d(.3,.3,.3);
  opacity: 0;
}
.dx-swatch-additional .dx-fade-zoom-animation.dx-enter.dx-enter-active,
.dx-swatch-additional .dx-fade-zoom-animation.dx-leave {
  transform: scale3d(1,1,1);
  opacity: 1;
}
.dx-swatch-additional .dx-button-disabled {
  cursor: default;
}
.dx-swatch-additional .dx-button {
  display: inline-flex;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  max-width: 100%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
}
.dx-swatch-additional .dx-button .dx-icon {
  -webkit-user-drag: none;
  display: inherit;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  background-size: contain;
  width: 18px;
  height: 18px;
  font-size: 18px;
  -webkit-margin-end: 0;
  margin-inline-end: 0;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
}
.dx-swatch-additional .dx-button a {
  text-decoration: none;
}
.dx-swatch-additional .dx-button-content {
  flex: 1 1 auto;
  height: 100%;
  max-height: 100%;
  display: inherit;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  line-height: 1.35715;
}
.dx-swatch-additional .dx-button-has-text .dx-button-content,
.dx-swatch-additional .dx-button-has-text .dx-button-content .dx-button-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dx-swatch-additional .dx-button-content > .dx-inkripple {
  display: none;
}
.dx-swatch-additional .dx-button-link {
  text-decoration: none;
}
.dx-swatch-additional .dx-button-submit-input {
  padding: 0;
  margin: 0;
  border: 0;
  height: 0;
  width: 0;
  font-size: 0;
  opacity: 0;
  visibility: hidden;
}
.dx-swatch-additional .dx-state-disabled .dx-button,
.dx-swatch-additional .dx-state-disabled.dx-button {
  cursor: default;
}
.dx-swatch-additional .dx-button-text {
  line-height: 1.2858;
}
.dx-swatch-additional .dx-button .dx-button-content {
  padding-block: 8px;
  -webkit-padding-start: 8px;
  padding-inline-start: 8px;
  -webkit-padding-end: 8px;
  padding-inline-end: 8px;
}
.dx-swatch-additional .dx-button-has-icon .dx-button-content {
  padding-block: 8px;
  -webkit-padding-start: 8px;
  padding-inline-start: 8px;
  -webkit-padding-end: 8px;
  padding-inline-end: 8px;
}
.dx-swatch-additional .dx-button-has-icon .dx-icon {
  width: 18px;
  height: 18px;
  font-size: 18px;
  -webkit-margin-end: 0;
  margin-inline-end: 0;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
}
.dx-swatch-additional .dx-button-has-text .dx-button-content {
  padding-block: 8px;
  -webkit-padding-start: 12px;
  padding-inline-start: 12px;
  -webkit-padding-end: 12px;
  padding-inline-end: 12px;
}
.dx-swatch-additional .dx-button-has-text .dx-icon {
  width: 18px;
  height: 18px;
  font-size: 18px;
  -webkit-margin-end: 9px;
  margin-inline-end: 9px;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
}
.dx-swatch-additional .dx-button {
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
}
.dx-swatch-additional .dx-button-mode-contained {
  background-color: #2a2a2a;
  border-color: #4d4d4d;
  color: #fff;
}
.dx-swatch-additional .dx-button-mode-contained .dx-icon {
  color: #fff;
}
.dx-swatch-additional .dx-button-mode-contained.dx-state-selected {
  background-color: #555;
}
.dx-swatch-additional .dx-button-mode-contained.dx-state-hover {
  background-color: #343434;
}
.dx-swatch-additional .dx-button-mode-contained.dx-state-focused {
  background-color: #3e3e3e;
}
.dx-swatch-additional .dx-button-mode-contained.dx-state-active {
  background-color: #676767;
  color: #fff;
}
.dx-swatch-additional .dx-button-mode-contained.dx-button-danger {
  background-color: #d9534f;
  border-color: transparent;
  color: #2a2a2a;
}
.dx-swatch-additional .dx-button-mode-contained.dx-button-danger .dx-icon {
  color: #2a2a2a;
}
.dx-swatch-additional .dx-button-mode-contained.dx-button-danger.dx-state-selected {
  background-color: #e89996;
}
.dx-swatch-additional .dx-button-mode-contained.dx-button-danger.dx-state-hover {
  background-color: #e17875;
}
.dx-swatch-additional .dx-button-mode-contained.dx-button-danger.dx-state-focused {
  background-color: #e48481;
}
.dx-swatch-additional .dx-button-mode-contained.dx-button-danger.dx-state-active {
  background-color: #eeb1af;
  color: #2a2a2a;
}
.dx-swatch-additional .dx-button-mode-contained.dx-button-success {
  background-color: #5cb85c;
  border-color: transparent;
  color: #2a2a2a;
}
.dx-swatch-additional .dx-button-mode-contained.dx-button-success .dx-icon {
  color: #2a2a2a;
}
.dx-swatch-additional .dx-button-mode-contained.dx-button-success.dx-state-selected {
  background-color: #98d298;
}
.dx-swatch-additional .dx-button-mode-contained.dx-button-success.dx-state-hover {
  background-color: #7cc67c;
}
.dx-swatch-additional .dx-button-mode-contained.dx-button-success.dx-state-focused {
  background-color: #87cb87;
}
.dx-swatch-additional .dx-button-mode-contained.dx-button-success.dx-state-active {
  background-color: #aedcae;
  color: #2a2a2a;
}
.dx-swatch-additional .dx-button-mode-contained.dx-button-default {
  background-color: #337ab7;
  border-color: transparent;
  color: #2a2a2a;
}
.dx-swatch-additional .dx-button-mode-contained.dx-button-default .dx-icon {
  color: #2a2a2a;
}
.dx-swatch-additional .dx-button-mode-contained.dx-button-default.dx-state-selected {
  background-color: #6ba4d6;
}
.dx-swatch-additional .dx-button-mode-contained.dx-button-default.dx-state-hover {
  background-color: #4b91cd;
}
.dx-swatch-additional .dx-button-mode-contained.dx-button-default.dx-state-focused {
  background-color: #5798d0;
}
.dx-swatch-additional .dx-button-mode-contained.dx-button-default.dx-state-active {
  background-color: #83b3dc;
  color: #2a2a2a;
}
.dx-swatch-additional .dx-button-mode-outlined {
  background-color: transparent;
  border-color: #4d4d4d;
  color: #fff;
}
.dx-swatch-additional .dx-button-mode-outlined .dx-icon {
  color: #fff;
}
.dx-swatch-additional .dx-button-mode-outlined.dx-state-selected {
  background-color: rgba(255,255,255,.17);
}
.dx-swatch-additional .dx-button-mode-outlined.dx-state-hover {
  background-color: rgba(255,255,255,.04);
}
.dx-swatch-additional .dx-button-mode-outlined.dx-state-focused {
  background-color: rgba(255,255,255,.08);
}
.dx-swatch-additional .dx-button-mode-outlined.dx-state-active {
  background-color: rgba(255,255,255,.24);
  color: #fff;
}
.dx-swatch-additional .dx-button-mode-outlined.dx-button-danger {
  background-color: transparent;
  border-color: #d43f3a;
  color: #d43f3a;
}
.dx-swatch-additional .dx-button-mode-outlined.dx-button-danger .dx-icon {
  color: #d43f3a;
}
.dx-swatch-additional .dx-button-mode-outlined.dx-button-danger.dx-state-selected {
  background-color: rgba(217,83,79,.3);
}
.dx-swatch-additional .dx-button-mode-outlined.dx-button-danger.dx-state-focused,
.dx-swatch-additional .dx-button-mode-outlined.dx-button-danger.dx-state-hover {
  background-color: rgba(212,63,58,.1);
}
.dx-swatch-additional .dx-button-mode-outlined.dx-button-danger.dx-state-active {
  background-color: rgba(212,63,58,.4);
  color: #d43f3a;
}
.dx-swatch-additional .dx-button-mode-outlined.dx-button-success {
  background-color: transparent;
  border-color: #4cae4c;
  color: #4cae4c;
}
.dx-swatch-additional .dx-button-mode-outlined.dx-button-success .dx-icon {
  color: #4cae4c;
}
.dx-swatch-additional .dx-button-mode-outlined.dx-button-success.dx-state-selected {
  background-color: rgba(92,184,92,.3);
}
.dx-swatch-additional .dx-button-mode-outlined.dx-button-success.dx-state-focused,
.dx-swatch-additional .dx-button-mode-outlined.dx-button-success.dx-state-hover {
  background-color: rgba(76,174,76,.1);
}
.dx-swatch-additional .dx-button-mode-outlined.dx-button-success.dx-state-active {
  background-color: rgba(76,174,76,.4);
  color: #4cae4c;
}
.dx-swatch-additional .dx-button-mode-outlined.dx-button-default {
  background-color: transparent;
  border-color: #2d6da3;
  color: #2d6da3;
}
.dx-swatch-additional .dx-button-mode-outlined.dx-button-default .dx-icon {
  color: #2d6da3;
}
.dx-swatch-additional .dx-button-mode-outlined.dx-button-default.dx-state-selected {
  background-color: rgba(51,122,183,.3);
}
.dx-swatch-additional .dx-button-mode-outlined.dx-button-default.dx-state-focused,
.dx-swatch-additional .dx-button-mode-outlined.dx-button-default.dx-state-hover {
  background-color: rgba(45,109,163,.1);
}
.dx-swatch-additional .dx-button-mode-outlined.dx-button-default.dx-state-active {
  background-color: rgba(45,109,163,.4);
  color: #2d6da3;
}
.dx-swatch-additional .dx-button-mode-text {
  background-color: transparent;
  border-color: transparent;
  color: #fff;
}
.dx-swatch-additional .dx-button-mode-text .dx-icon {
  color: #fff;
}
.dx-swatch-additional .dx-button-mode-text.dx-state-selected {
  background-color: rgba(255,255,255,.17);
}
.dx-swatch-additional .dx-button-mode-text.dx-state-hover {
  background-color: rgba(255,255,255,.04);
}
.dx-swatch-additional .dx-button-mode-text.dx-state-focused {
  background-color: rgba(255,255,255,.08);
}
.dx-swatch-additional .dx-button-mode-text.dx-state-active {
  background-color: rgba(255,255,255,.24);
  color: #fff;
}
.dx-swatch-additional .dx-button-mode-text.dx-button-danger {
  background-color: transparent;
  border-color: transparent;
  color: #d43f3a;
}
.dx-swatch-additional .dx-button-mode-text.dx-button-danger .dx-icon {
  color: #d43f3a;
}
.dx-swatch-additional .dx-button-mode-text.dx-button-danger.dx-state-selected {
  background-color: rgba(217,83,79,.3);
}
.dx-swatch-additional .dx-button-mode-text.dx-button-danger.dx-state-focused,
.dx-swatch-additional .dx-button-mode-text.dx-button-danger.dx-state-hover {
  background-color: rgba(212,63,58,.1);
}
.dx-swatch-additional .dx-button-mode-text.dx-button-danger.dx-state-active {
  background-color: rgba(212,63,58,.4);
  color: #d43f3a;
}
.dx-swatch-additional .dx-button-mode-text.dx-button-success {
  background-color: transparent;
  border-color: transparent;
  color: #4cae4c;
}
.dx-swatch-additional .dx-button-mode-text.dx-button-success .dx-icon {
  color: #4cae4c;
}
.dx-swatch-additional .dx-button-mode-text.dx-button-success.dx-state-selected {
  background-color: rgba(92,184,92,.3);
}
.dx-swatch-additional .dx-button-mode-text.dx-button-success.dx-state-focused,
.dx-swatch-additional .dx-button-mode-text.dx-button-success.dx-state-hover {
  background-color: rgba(76,174,76,.1);
}
.dx-swatch-additional .dx-button-mode-text.dx-button-success.dx-state-active {
  background-color: rgba(76,174,76,.4);
  color: #4cae4c;
}
.dx-swatch-additional .dx-button-mode-text.dx-button-default {
  background-color: transparent;
  border-color: transparent;
  color: #2d6da3;
}
.dx-swatch-additional .dx-button-mode-text.dx-button-default .dx-icon {
  color: #2d6da3;
}
.dx-swatch-additional .dx-button-mode-text.dx-button-default.dx-state-selected {
  background-color: rgba(51,122,183,.3);
}
.dx-swatch-additional .dx-button-mode-text.dx-button-default.dx-state-focused,
.dx-swatch-additional .dx-button-mode-text.dx-button-default.dx-state-hover {
  background-color: rgba(45,109,163,.1);
}
.dx-swatch-additional .dx-button-mode-text.dx-button-default.dx-state-active {
  background-color: rgba(45,109,163,.4);
  color: #2d6da3;
}
.dx-swatch-additional .dx-checkbox {
  display: inline-block;
  cursor: pointer;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}
.dx-swatch-additional .dx-checkbox.dx-state-readonly {
  cursor: default;
}
.dx-swatch-additional .dx-checkbox-icon {
  flex-shrink: 0;
  position: relative;
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
}
.dx-swatch-additional .dx-checkbox-container {
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  text-overflow: clip;
}
.dx-swatch-additional .dx-checkbox-text {
  text-align: start;
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: normal;
  -webkit-padding-start: 5px;
  padding-inline-start: 5px;
}
.dx-swatch-additional .dx-state-disabled .dx-checkbox,
.dx-swatch-additional .dx-state-disabled.dx-checkbox {
  cursor: default;
  opacity: 1;
}
.dx-swatch-additional .dx-checkbox {
  line-height: 0;
}
.dx-swatch-additional .dx-checkbox.dx-state-readonly .dx-checkbox-icon {
  border-color: #363636;
  background-color: #2a2a2a;
}
.dx-swatch-additional .dx-checkbox.dx-state-hover .dx-checkbox-icon {
  border: 1px solid #5798d0;
}
.dx-swatch-additional .dx-checkbox.dx-state-focused .dx-checkbox-icon {
  border: 1px solid #337ab7;
}
.dx-swatch-additional .dx-checkbox.dx-state-active .dx-checkbox-icon {
  background-color: rgba(201,201,201,.2);
}
.dx-swatch-additional .dx-checkbox-icon {
  width: 22px;
  height: 22px;
  border-radius: 2px;
  border: 1px solid #4d4d4d;
  background-color: #2a2a2a;
}
.dx-swatch-additional .dx-checkbox-checked .dx-checkbox-icon {
  font: 16px/1em DXIcons;
  color: #337ab7;
  text-align: center;
}
.dx-swatch-additional .dx-checkbox-checked .dx-checkbox-icon::before {
  content: "\f005";
  position: relative;
  display: block;
  width: 1em;
  top: 50%;
  margin-top: -.5em;
  left: 50%;
  margin-left: -.5em;
}
.dx-swatch-additional .dx-rtl .dx-checkbox-checked .dx-checkbox-icon::before,
.dx-swatch-additional .dx-rtl.dx-checkbox-checked .dx-checkbox-icon::before {
  left: 0;
  margin-left: 0;
  right: 50%;
  margin-right: -.5em;
}
.dx-swatch-additional .dx-checkbox-indeterminate .dx-checkbox-icon {
  color: #337ab7;
  text-align: center;
  line-height: 1em;
  font-size: 12px;
}
.dx-swatch-additional .dx-checkbox-indeterminate .dx-checkbox-icon::before {
  position: relative;
  display: block;
  width: 1em;
  top: 50%;
  margin-top: -.5em;
  left: 50%;
  margin-left: -.5em;
  content: "";
  background-color: #337ab7;
  border-radius: 2px;
  height: 1em;
}
.dx-swatch-additional .dx-rtl .dx-checkbox-indeterminate .dx-checkbox-icon::before,
.dx-swatch-additional .dx-rtl.dx-checkbox-indeterminate .dx-checkbox-icon::before {
  left: 0;
  margin-left: 0;
  right: 50%;
  margin-right: -.5em;
}
.dx-swatch-additional .dx-state-disabled .dx-checkbox .dx-checkbox-icon,
.dx-swatch-additional .dx-state-disabled .dx-checkbox-text,
.dx-swatch-additional .dx-state-disabled.dx-checkbox .dx-checkbox-icon {
  opacity: .4;
}
.dx-swatch-additional .dx-invalid .dx-checkbox-container .dx-checkbox-icon {
  border: 1px solid rgba(217,83,79,.4);
}
.dx-swatch-additional .dx-invalid.dx-state-focused .dx-checkbox-container .dx-checkbox-icon {
  border-color: #d9534f;
}
.dx-swatch-additional .dx-overlay-wrapper {
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: 1000;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  font-family: "Helvetica Neue","Segoe UI",helvetica,verdana,sans-serif;
  line-height: 1.35715;
}
.dx-swatch-additional .dx-overlay-wrapper,
.dx-swatch-additional .dx-overlay-wrapper *,
.dx-swatch-additional .dx-overlay-wrapper ::after,
.dx-swatch-additional .dx-overlay-wrapper ::before,
.dx-swatch-additional .dx-overlay-wrapper::after,
.dx-swatch-additional .dx-overlay-wrapper::before {
  box-sizing: border-box;
}
.dx-swatch-additional .dx-overlay-shader {
  pointer-events: auto;
  background-color: rgba(42,42,42,.8);
}
.dx-swatch-additional .dx-overlay-content {
  position: absolute;
  pointer-events: auto;
  z-index: 1000;
  outline: 0;
  overflow: clip;
}
.dx-swatch-additional .dx-overlay-content > .dx-template-wrapper {
  height: 100%;
  width: 100%;
}
.dx-swatch-additional .dx-overlay-wrapper input,
.dx-swatch-additional .dx-overlay-wrapper textarea {
  font-family: "Helvetica Neue","Segoe UI",helvetica,verdana,sans-serif;
  line-height: 1.35715;
}
.dx-swatch-additional .dx-validationsummary-item {
  color: #d9534f;
}
.dx-swatch-additional .dx-invalid-message > .dx-overlay-content {
  color: #2a2a2a;
  background-color: #d9534f;
  border-radius: 4px;
}
.dx-swatch-additional .dx-searchbox .dx-icon-search {
  display: block;
  pointer-events: none;
}
.dx-swatch-additional .dx-searchbox .dx-icon-search::before {
  display: inline-block;
  overflow: hidden;
  box-sizing: content-box;
  position: static;
  text-indent: 0;
}
.dx-swatch-additional .dx-texteditor {
  display: block;
}
.dx-swatch-additional .dx-texteditor.dx-texteditor-with-floating-label,
.dx-swatch-additional .dx-texteditor.dx-texteditor-with-label {
  position: relative;
}
.dx-swatch-additional .dx-placeholder {
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  max-width: 100%;
  width: auto;
  height: 100%;
  text-align: left;
  cursor: text;
  pointer-events: none;
  color: #999;
}
.dx-swatch-additional .dx-placeholder::before {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  content: attr(DATA-DX_PLACEHOLDER);
  pointer-events: none;
  white-space: nowrap;
}
.dx-swatch-additional .dx-placeholder::after {
  content: " ";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.dx-swatch-additional .dx-texteditor-container {
  display: flex;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.dx-swatch-additional .dx-texteditor-buttons-container {
  display: flex;
  width: auto;
  flex-grow: 0;
  flex-basis: content;
  align-items: center;
}
.dx-swatch-additional .dx-texteditor-input-container {
  display: flex;
  flex-grow: 1;
  position: relative;
  align-items: baseline;
}
.dx-swatch-additional .dx-texteditor-input {
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  outline: 0;
  border: 0;
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text;
}
.dx-swatch-additional .dx-texteditor-input:-webkit-autofill + .dx-placeholder {
  display: none!important;
}
.dx-swatch-additional .dx-texteditor-input:autofill + .dx-placeholder {
  display: none!important;
}
.dx-swatch-additional .dx-texteditor-input:-moz-ui-invalid {
  box-shadow: none;
}
.dx-swatch-additional .dx-texteditor-input::-webkit-input-placeholder {
  color: transparent;
}
.dx-swatch-additional .dx-texteditor-input::-moz-placeholder {
  color: transparent;
}
.dx-swatch-additional .dx-texteditor-input::placeholder {
  color: transparent;
}
.dx-swatch-additional .dx-show-clear-button {
  position: relative;
}
.dx-swatch-additional .dx-clear-button-area {
  height: 100%;
  width: 34px;
  position: relative;
  cursor: pointer;
  text-align: justify;
}
.dx-swatch-additional .dx-clear-button-area .dx-icon-clear {
  position: absolute;
  display: inline-block;
  background-size: contain;
}
.dx-swatch-additional .dx-texteditor-empty .dx-clear-button-area {
  display: none;
}
.dx-swatch-additional .dx-state-disabled .dx-placeholder {
  cursor: auto;
}
.dx-swatch-additional .dx-state-disabled .dx-clear-button-area {
  display: none;
}
.dx-swatch-additional .dx-state-disabled .dx-texteditor-input {
  opacity: 1;
}
.dx-swatch-additional .dx-texteditor.dx-editor-outlined {
  background: #2a2a2a;
  border: 1px solid #4d4d4d;
  border-radius: 4px;
}
.dx-swatch-additional .dx-texteditor.dx-editor-underlined {
  background: 0 0;
  border-bottom: 1px solid #4d4d4d;
  border-radius: 0;
}
.dx-swatch-additional .dx-texteditor.dx-editor-filled {
  background: rgba(255,255,255,.05);
  border: 1px solid transparent;
  border-radius: 4px;
}
.dx-swatch-additional .dx-texteditor.dx-state-readonly {
  border-style: dashed;
}
.dx-swatch-additional .dx-texteditor.dx-state-readonly.dx-editor-filled {
  border-color: transparent;
  background-color: rgba(255,255,255,.03);
}
.dx-swatch-additional .dx-texteditor.dx-state-readonly.dx-editor-filled.dx-state-focused {
  background-color: rgba(255,255,255,.03);
}
.dx-swatch-additional .dx-texteditor.dx-state-readonly.dx-editor-filled.dx-state-hover {
  background-color: rgba(51,122,183,.07);
}
.dx-swatch-additional .dx-texteditor.dx-state-readonly.dx-editor-underlined {
  border-style: none none dashed;
}
.dx-swatch-additional .dx-texteditor.dx-state-hover {
  border-color: rgba(51,122,183,.4);
}
.dx-swatch-additional .dx-texteditor.dx-state-hover.dx-editor-filled {
  border-color: transparent;
  background-color: rgba(51,122,183,.1);
}
.dx-swatch-additional .dx-texteditor.dx-state-hover.dx-invalid.dx-editor-filled,
.dx-swatch-additional .dx-texteditor.dx-state-hover.dx-invalid.dx-editor-outlined {
  border-color: #d9534f;
}
.dx-swatch-additional .dx-texteditor.dx-state-focused .dx-texteditor-label {
  color: #337ab7;
}
.dx-swatch-additional .dx-texteditor.dx-state-focused.dx-invalid .dx-texteditor-label {
  color: #d9534f;
}
.dx-swatch-additional .dx-texteditor.dx-texteditor-label-outside .dx-texteditor-label {
  color: #fff;
}
.dx-swatch-additional .dx-texteditor.dx-texteditor-label-outside.dx-invalid .dx-texteditor-label {
  color: #d9534f;
}
.dx-swatch-additional .dx-texteditor.dx-state-active.dx-editor-filled,
.dx-swatch-additional .dx-texteditor.dx-state-active.dx-editor-outlined,
.dx-swatch-additional .dx-texteditor.dx-state-active.dx-editor-underlined,
.dx-swatch-additional .dx-texteditor.dx-state-focused.dx-editor-filled,
.dx-swatch-additional .dx-texteditor.dx-state-focused.dx-editor-outlined,
.dx-swatch-additional .dx-texteditor.dx-state-focused.dx-editor-underlined {
  border-color: #337ab7;
}
.dx-swatch-additional .dx-texteditor.dx-state-active.dx-editor-filled,
.dx-swatch-additional .dx-texteditor.dx-state-focused.dx-editor-filled {
  background-color: transparent;
}
.dx-swatch-additional .dx-texteditor.dx-invalid .dx-texteditor-input,
.dx-swatch-additional .dx-texteditor.dx-valid .dx-texteditor-input {
  -webkit-padding-end: 34px;
  padding-inline-end: 34px;
}
.dx-swatch-additional .dx-texteditor.dx-invalid .dx-texteditor-input-container::after,
.dx-swatch-additional .dx-texteditor.dx-valid .dx-texteditor-input-container::after {
  inset-inline-end: 4px;
}
.dx-swatch-additional .dx-texteditor.dx-validation-pending .dx-texteditor-input {
  -webkit-padding-end: 34px;
  padding-inline-end: 34px;
}
.dx-swatch-additional .dx-texteditor.dx-validation-pending .dx-texteditor-input-container .dx-pending-indicator {
  inset-inline-end: 4px;
}
.dx-swatch-additional .dx-show-clear-button .dx-clear-button-area {
  width: 34px;
  min-width: 34px;
  right: 0;
}
.dx-swatch-additional .dx-show-clear-button .dx-icon-clear {
  color: #999;
  position: absolute;
  top: 50%;
  margin-top: -17px;
  padding: 8px;
  width: 34px;
  height: 34px;
  background-position: 8px 8px;
  background-size: 18px 18px;
  font-size: 18px;
  text-align: center;
  line-height: 18px;
}
.dx-swatch-additional .dx-placeholder::before {
  padding: 7px 9px 8px;
}
.dx-swatch-additional .dx-texteditor-input {
  margin: 0;
  padding: 7px 9px 8px;
  background: rgba(0,0,0,0);
  color: #fff;
  font-size: 1em;
  border-radius: 4px;
  min-height: 34px;
}
.dx-swatch-additional .dx-editor-filled .dx-texteditor-input {
  background: 0 0;
}
.dx-swatch-additional .dx-texteditor-label {
  position: absolute;
  font-size: 12px;
  color: #999;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  pointer-events: none;
  cursor: text;
  width: 100%;
  display: flex;
  flex-direction: row;
  top: 0;
  inset-inline-start: 0;
}
.dx-swatch-additional .dx-texteditor-label .dx-label {
  display: flex;
}
.dx-swatch-additional .dx-texteditor-label .dx-label span {
  text-overflow: ellipsis;
  width: auto;
  max-width: 100%;
  overflow: hidden;
  display: block;
}
.dx-swatch-additional .dx-invalid .dx-texteditor-label {
  color: #d9534f;
}
.dx-swatch-additional .dx-editor-outlined .dx-texteditor-label {
  inset: 0;
}
.dx-swatch-additional .dx-editor-outlined .dx-label {
  padding: 0 2px;
  border-top-width: 0;
  border-bottom-width: 1px;
  border-inline-width: 0;
  border-style: solid;
  border-top-color: #4d4d4d;
  border-bottom-color: #4d4d4d;
  margin-left: -2px;
  margin-right: -2px;
  border-radius: 1px;
  -webkit-clip-path: inset(-12px 1px -1px 1px);
  clip-path: inset(-12px 1px -1px 1px);
}
.dx-swatch-additional .dx-editor-outlined .dx-label span {
  position: relative;
  font-size: 12px;
  top: 0;
  margin-top: 0;
  transform: translate(0,-7.5px);
  height: 19px;
  line-height: normal;
}
.dx-swatch-additional .dx-editor-outlined .dx-label-before {
  border-block-width: 1px;
  border-inline-start-width: 1px;
  border-inline-end-width: 0;
  border-style: solid;
  border-top-color: #4d4d4d;
  border-bottom-color: #4d4d4d;
  border-inline-start-color: #4d4d4d;
  border-start-start-radius: 4px;
  border-end-start-radius: 4px;
  min-width: 9px;
  -webkit-clip-path: inset(-1px 1px -1px -1px);
  clip-path: inset(-1px 1px -1px -1px);
}
.dx-swatch-additional .dx-editor-outlined .dx-label-after {
  flex-grow: 1;
  min-width: 9px;
  border-block-width: 1px;
  border-inline-end-width: 1px;
  border-inline-start-width: 0;
  border-style: solid;
  border-top-color: #4d4d4d;
  border-bottom-color: #4d4d4d;
  border-inline-end-color: #4d4d4d;
  border-start-end-radius: 4px;
  border-end-end-radius: 4px;
  -webkit-clip-path: inset(-1px -1px -1px 1px);
  clip-path: inset(-1px -1px -1px 1px);
}
.dx-swatch-additional .dx-editor-outlined.dx-rtl .dx-label-before {
  -webkit-clip-path: inset(-1px -1px -1px 1px);
  clip-path: inset(-1px -1px -1px 1px);
}
.dx-swatch-additional .dx-editor-outlined.dx-rtl .dx-label-after {
  -webkit-clip-path: inset(-1px 1px -1px -1px);
  clip-path: inset(-1px 1px -1px -1px);
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-daterangebox .dx-label,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label:not(.dx-texteditor-label-outside).dx-daterangebox .dx-label {
  position: relative;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-daterangebox .dx-label::before,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label:not(.dx-texteditor-label-outside).dx-daterangebox .dx-label::before {
  content: "";
  width: 100%;
  position: absolute;
  height: 3px;
  top: -1.5px;
  -webkit-margin-start: -2px;
  margin-inline-start: -2px;
  background-color: #2a2a2a;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label {
  outline: 0;
  margin-top: 7.5px;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor:not(.dx-daterangebox),
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-texteditor:not(.dx-daterangebox) {
  border: none;
  box-shadow: none;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-readonly .dx-label,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-readonly .dx-label-after,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-readonly .dx-label-before,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-state-readonly .dx-label,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-state-readonly .dx-label-after,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-state-readonly .dx-label-before {
  border-style: dashed;
  border-color: #4d4d4d;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-disabled .dx-label,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-disabled .dx-label-after,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-disabled .dx-label-before,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-state-disabled .dx-label,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-state-disabled .dx-label-after,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-state-disabled .dx-label-before {
  border-color: rgba(255,255,255,.5);
  border-bottom-color: rgba(255,255,255,.5);
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-invalid .dx-label,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-invalid .dx-label-after,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-invalid .dx-label-before,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-invalid .dx-label,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-invalid .dx-label-after,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-invalid .dx-label-before {
  border-color: rgba(217,83,79,.4);
  border-bottom-color: rgba(217,83,79,.4);
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-invalid.dx-state-focused .dx-label,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-invalid.dx-state-focused .dx-label-after,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-invalid.dx-state-focused .dx-label-before,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-invalid.dx-state-hover .dx-label,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-invalid.dx-state-hover .dx-label-after,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-invalid.dx-state-hover .dx-label-before,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-invalid.dx-state-focused .dx-label,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-invalid.dx-state-focused .dx-label-after,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-invalid.dx-state-focused .dx-label-before,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-invalid.dx-state-hover .dx-label,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-invalid.dx-state-hover .dx-label-after,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-invalid.dx-state-hover .dx-label-before {
  border-color: #d9534f;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-hover .dx-label,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-hover .dx-label-after,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-hover .dx-label-before,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-state-hover .dx-label,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-state-hover .dx-label-after,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-state-hover .dx-label-before {
  border-color: rgba(51,122,183,.4);
  border-bottom-color: rgba(51,122,183,.4);
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused .dx-label,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused .dx-label-after,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused .dx-label-before,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-state-focused .dx-label,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-state-focused .dx-label-after,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-state-focused .dx-label-before {
  border-color: #337ab7;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused .dx-label,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-state-focused .dx-label {
  border-top-width: 0;
  border-bottom-width: 1px;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused .dx-label-before,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-state-focused .dx-label-before {
  border-width: 1px;
  border-inline-end-width: 0;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused .dx-label-after,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-label.dx-state-focused .dx-label-after {
  border-width: 1px;
  border-inline-start-width: 0;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-label-outside {
  margin-top: 24px;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-label-outside .dx-texteditor-label {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  user-select: auto;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-label-outside .dx-label-after,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-label-outside .dx-label-before {
  -webkit-clip-path: none;
  clip-path: none;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-label-outside .dx-label {
  margin: 0;
  -webkit-clip-path: none;
  clip-path: none;
  border-top-width: 1px;
  border-radius: 0;
  border-start-end-radius: 1px;
  border-end-end-radius: 1px;
  -webkit-margin-start: 0;
  margin-inline-start: 0;
  padding: 0;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-label-outside .dx-label span {
  position: absolute;
  width: 100%;
  cursor: default;
  pointer-events: auto;
  font-size: 14px;
  margin-top: 0;
  top: -24px;
  height: 1.4285714286em;
  line-height: 1.4285714286em;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label .dx-placeholder {
  display: none;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-dropdowneditor-active .dx-placeholder,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-state-focused .dx-placeholder {
  display: block;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty .dx-label {
  padding: 0 2px;
  border-top-width: 1px;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty .dx-label span {
  transition: font-size .2s cubic-bezier(0, 0, .2, 1),transform .2s cubic-bezier(0, 0, .2, 1),top .2s cubic-bezier(0, 0, .2, 1);
  transform: translate(0,0);
  font-size: 14px;
  top: 50%;
  margin-top: -8.5px;
  height: 19px;
  line-height: normal;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty .dx-label::before {
  background-color: transparent;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-textarea .dx-label span {
  top: 7px;
  margin-top: 0;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-dropdowneditor-active .dx-label,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-focused .dx-label {
  border-top-width: 0;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-dropdowneditor-active .dx-label span,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-focused .dx-label span {
  transform: translate(0,-7.5px);
  font-size: 12px;
  margin-top: 0;
  top: 0;
  height: 19px;
  line-height: normal;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-dropdowneditor-active .dx-label::before,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-focused .dx-label::before {
  background-color: #2a2a2a;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-dropdowneditor-active.dx-textarea .dx-label span,
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-focused.dx-textarea .dx-label span {
  top: 0;
  margin-top: 0;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-readonly .dx-placeholder {
  display: none;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-readonly.dx-state-focused .dx-label {
  padding: 0 2px;
  border-top-width: 1px;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-readonly.dx-state-focused .dx-label span {
  transition: font-size .2s cubic-bezier(0, 0, .2, 1),transform .2s cubic-bezier(0, 0, .2, 1),top .2s cubic-bezier(0, 0, .2, 1);
  transform: translate(0,0);
  font-size: 14px;
  top: 50%;
  margin-top: -8.5px;
  height: 19px;
  line-height: normal;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-readonly.dx-state-focused .dx-label::before {
  background-color: transparent;
}
.dx-swatch-additional .dx-editor-outlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-readonly.dx-state-focused.dx-textarea .dx-label span {
  top: 7px;
  margin-top: 0;
}
.dx-swatch-additional .dx-editor-filled .dx-texteditor-label .dx-label-after,
.dx-swatch-additional .dx-editor-filled .dx-texteditor-label .dx-label-before {
  min-width: 9px;
}
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-floating-label .dx-lookup-field,
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-floating-label .dx-placeholder::before,
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-floating-label .dx-texteditor-input,
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-label:not(.dx-texteditor-label-outside) .dx-lookup-field,
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-label:not(.dx-texteditor-label-outside) .dx-placeholder::before,
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-label:not(.dx-texteditor-label-outside) .dx-texteditor-input {
  padding-top: 13px;
  padding-bottom: 2px;
}
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-floating-label .dx-lookup-field .dx-placeholder::before,
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-label:not(.dx-texteditor-label-outside) .dx-lookup-field .dx-placeholder::before {
  padding: 0;
}
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-floating-label .dx-texteditor-label,
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-label .dx-texteditor-label {
  font-size: 12px;
  height: 19px;
  line-height: 1.35715;
  top: 0;
  margin-top: 0;
}
.dx-swatch-additional .dx-editor-filled.dx-texteditor-label-outside {
  margin-top: 24px;
}
.dx-swatch-additional .dx-editor-filled.dx-texteditor-label-outside .dx-texteditor-label {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  user-select: auto;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  margin-top: 0;
  top: -24px;
}
.dx-swatch-additional .dx-editor-filled.dx-texteditor-label-outside .dx-label {
  cursor: default;
}
.dx-swatch-additional .dx-editor-filled.dx-texteditor-label-outside .dx-label span {
  width: 100%;
  pointer-events: auto;
  position: absolute;
  margin-bottom: 0;
}
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-floating-label.dx-texteditor-empty .dx-placeholder,
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-readonly .dx-placeholder {
  display: none;
}
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-floating-label.dx-texteditor-empty .dx-texteditor-label,
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-readonly .dx-texteditor-label {
  transition: font-size .2s cubic-bezier(0, 0, .2, 1),transform .2s cubic-bezier(0, 0, .2, 1),top .2s cubic-bezier(0, 0, .2, 1);
  font-size: 14px;
  height: 19px;
  line-height: 1.35715;
  top: 50%;
  margin-top: -9.5px;
}
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-readonly.dx-textarea .dx-texteditor-label,
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-textarea .dx-texteditor-label {
  top: 13px;
  margin-top: 0;
}
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-floating-label.dx-dropdowneditor-active .dx-placeholder,
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-floating-label.dx-state-focused .dx-placeholder {
  display: block;
}
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-floating-label.dx-dropdowneditor-active .dx-texteditor-label,
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-floating-label.dx-state-focused .dx-texteditor-label {
  height: 19px;
  line-height: 1.35715;
  font-size: 12px;
  top: 0;
  margin-top: 0;
}
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-floating-label.dx-dropdowneditor-active.dx-textarea .dx-texteditor-label,
.dx-swatch-additional .dx-editor-filled.dx-texteditor-with-floating-label.dx-state-focused.dx-textarea .dx-texteditor-label {
  top: 0;
  margin-top: 0;
}
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-floating-label .dx-lookup-field,
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-floating-label .dx-placeholder::before,
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-floating-label .dx-texteditor-input,
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-label:not(.dx-texteditor-label-outside) .dx-lookup-field,
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-label:not(.dx-texteditor-label-outside) .dx-placeholder::before,
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-label:not(.dx-texteditor-label-outside) .dx-texteditor-input {
  padding-top: 11px;
  padding-bottom: 4px;
}
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-floating-label .dx-lookup-field .dx-placeholder::before,
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-label:not(.dx-texteditor-label-outside) .dx-lookup-field .dx-placeholder::before {
  padding: 0;
}
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-floating-label .dx-texteditor-label,
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-label .dx-texteditor-label {
  transform: translate(0,0);
  height: 19px;
  line-height: 1.35715;
  top: 0;
  margin-top: 0;
}
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-label-outside {
  margin-top: 24px;
}
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-label-outside .dx-texteditor-label {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  user-select: auto;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  top: -24px;
  margin-top: 0;
}
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-label-outside .dx-label {
  cursor: default;
}
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-label-outside .dx-label span {
  width: 100%;
  pointer-events: auto;
  position: absolute;
  margin-bottom: 0;
}
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-floating-label.dx-texteditor-empty .dx-placeholder,
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-readonly .dx-placeholder {
  display: none;
}
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-floating-label.dx-texteditor-empty .dx-texteditor-label,
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-readonly .dx-texteditor-label {
  transition: font-size .2s cubic-bezier(0, 0, .2, 1),transform .2s cubic-bezier(0, 0, .2, 1),top .2s cubic-bezier(0, 0, .2, 1);
  transform: translate(0,0);
  font-size: 14px;
  height: 19px;
  line-height: 1.35715;
  top: 50%;
  margin-top: -9.5px;
}
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-state-readonly.dx-textarea .dx-texteditor-label,
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-floating-label.dx-texteditor-empty.dx-textarea .dx-texteditor-label {
  top: 11px;
  margin-top: 0;
}
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-floating-label.dx-dropdowneditor-active .dx-placeholder,
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-floating-label.dx-state-focused .dx-placeholder {
  display: block;
}
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-floating-label.dx-dropdowneditor-active .dx-texteditor-label,
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-floating-label.dx-state-focused .dx-texteditor-label {
  transform: translate(0,0);
  font-size: 12px;
  height: 19px;
  line-height: 1.35715;
  top: 0;
  margin-top: 0;
}
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-floating-label.dx-dropdowneditor-active.dx-textarea .dx-texteditor-label,
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-floating-label.dx-state-focused.dx-textarea .dx-texteditor-label {
  top: 0;
  margin-top: 0;
}
.dx-swatch-additional .dx-editor-underlined .dx-placeholder::before,
.dx-swatch-additional .dx-editor-underlined .dx-texteditor-input {
  padding-left: 0;
  padding-right: 0;
}
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-before-buttons .dx-texteditor-input-container .dx-placeholder,
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-before-buttons .dx-texteditor-input-container .dx-texteditor-input,
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-before-buttons .dx-texteditor-label .dx-label {
  -webkit-padding-start: 9px;
  padding-inline-start: 9px;
}
.dx-swatch-additional .dx-invalid.dx-texteditor {
  border-color: rgba(217,83,79,.4);
}
.dx-swatch-additional .dx-invalid.dx-texteditor.dx-editor-filled.dx-state-focused,
.dx-swatch-additional .dx-invalid.dx-texteditor.dx-editor-filled.dx-state-hover,
.dx-swatch-additional .dx-invalid.dx-texteditor.dx-editor-outlined.dx-state-focused,
.dx-swatch-additional .dx-invalid.dx-texteditor.dx-editor-outlined.dx-state-hover,
.dx-swatch-additional .dx-invalid.dx-texteditor.dx-editor-underlined.dx-state-focused,
.dx-swatch-additional .dx-invalid.dx-texteditor.dx-editor-underlined.dx-state-hover {
  border-color: #d9534f;
}
.dx-swatch-additional .dx-invalid.dx-texteditor.dx-editor-filled.dx-show-invalid-badge .dx-texteditor-input-container::after,
.dx-swatch-additional .dx-invalid.dx-texteditor.dx-editor-outlined.dx-show-invalid-badge .dx-texteditor-input-container::after,
.dx-swatch-additional .dx-invalid.dx-texteditor.dx-editor-underlined.dx-show-invalid-badge .dx-texteditor-input-container::after {
  pointer-events: none;
  font-weight: 700;
  position: absolute;
  top: 50%;
  margin-top: -9px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  font-size: 13px;
  background-color: #d9534f;
  color: #2a2a2a;
  content: "!";
  border-radius: 50%;
}
.dx-swatch-additional .dx-invalid.dx-texteditor.dx-editor-filled {
  background-color: rgba(217,83,79,.2);
  border-color: transparent;
}
.dx-swatch-additional .dx-invalid.dx-texteditor.dx-editor-filled.dx-state-focused {
  background: 0 0;
}
.dx-swatch-additional .dx-valid.dx-texteditor.dx-editor-filled .dx-texteditor-input-container::after,
.dx-swatch-additional .dx-valid.dx-texteditor.dx-editor-outlined .dx-texteditor-input-container::after,
.dx-swatch-additional .dx-valid.dx-texteditor.dx-editor-underlined .dx-texteditor-input-container::after {
  pointer-events: none;
  font-weight: 700;
  position: absolute;
  top: 50%;
  margin-top: -9px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  font-size: 13px;
  font-family: DXIcons,sans-serif;
  color: #5cb85c;
  content: "\f005";
  opacity: 0;
  transform: scale(0);
  animation: .3s cubic-bezier(1,.008,.565,1.65) .1s forwards dx-valid-badge-frames;
}
.dx-swatch-additional .dx-validation-pending.dx-texteditor.dx-editor-filled .dx-texteditor-input-container .dx-pending-indicator,
.dx-swatch-additional .dx-validation-pending.dx-texteditor.dx-editor-outlined .dx-texteditor-input-container .dx-pending-indicator,
.dx-swatch-additional .dx-validation-pending.dx-texteditor.dx-editor-underlined .dx-texteditor-input-container .dx-pending-indicator {
  pointer-events: none;
  position: absolute;
  top: 50%;
  margin-top: -11px;
  width: 22px;
  height: 22px;
}
.dx-swatch-additional .dx-texteditor-buttons-container > .dx-button {
  margin-top: 1px;
  margin-bottom: 1px;
  -webkit-margin-start: 1px;
  margin-inline-start: 1px;
  -webkit-margin-end: 1px;
  margin-inline-end: 1px;
}
.dx-swatch-additional .dx-texteditor-buttons-container > .dx-button > .dx-button-content {
  padding-top: 5px;
  padding-bottom: 6px;
}
.dx-swatch-additional .dx-texteditor-buttons-container > .dx-button.dx-button-has-icon:not(.dx-button-has-text) > .dx-button-content {
  padding: 6px;
}
.dx-swatch-additional .dx-editor-filled .dx-texteditor-buttons-container:first-child > .dx-button:last-child,
.dx-swatch-additional .dx-editor-outlined .dx-texteditor-buttons-container:first-child > .dx-button:last-child {
  -webkit-margin-end: 0;
  margin-inline-end: 0;
}
.dx-swatch-additional .dx-editor-filled .dx-texteditor-buttons-container:last-child > .dx-button:first-child,
.dx-swatch-additional .dx-editor-outlined .dx-texteditor-buttons-container:last-child > .dx-button:first-child {
  -webkit-margin-start: 0;
  margin-inline-start: 0;
}
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-before-buttons .dx-texteditor-buttons-container > .dx-button:first-child {
  margin-left: 0;
}
.dx-swatch-additional .dx-editor-underlined.dx-texteditor-with-before-buttons .dx-texteditor-buttons-container > .dx-button:last-child {
  margin-right: 0;
}
.dx-swatch-additional .dx-searchbox .dx-icon-search {
  font: 17px/18px DXIcons;
  position: absolute;
  top: 50%;
  margin-top: -17px;
  width: 34px;
  height: 34px;
  background-position: 8px 8px;
  background-size: 18px 18px;
  padding: 8px;
  text-align: center;
  color: #999;
}
.dx-swatch-additional .dx-searchbox .dx-icon-search::before {
  content: "\f027";
  color: #999;
}
.dx-swatch-additional .dx-searchbox .dx-placeholder::before,
.dx-swatch-additional .dx-searchbox .dx-texteditor-input,
.dx-swatch-additional .dx-searchbox.dx-editor-underlined .dx-texteditor-buttons-container+.dx-texteditor-input-container .dx-texteditor-input,
.dx-swatch-additional .dx-searchbox.dx-editor-underlined .dx-texteditor-input {
  -webkit-padding-start: 34px;
  padding-inline-start: 34px;
}
.dx-swatch-additional .dx-searchbox.dx-texteditor .dx-texteditor-label .dx-label-before {
  min-width: 0;
}
.dx-swatch-additional .dx-searchbar {
  padding-bottom: 5px;
}
.dx-swatch-additional .dx-searchbar .dx-texteditor {
  margin: 0;
}
.dx-swatch-additional .dx-loadindicator {
  width: 32px;
  height: 32px;
  display: inline-block;
  overflow: hidden;
  border: none;
  background-color: transparent;
}
.dx-swatch-additional .dx-loadindicator-wrapper {
  width: 100%;
  height: 100%;
  font-size: 32px;
  margin: auto;
}
.dx-swatch-additional .dx-loadindicator-image {
  background-size: contain;
  transform-origin: 50% 50%;
  background-position: 50%;
  background-repeat: no-repeat;
}
.dx-swatch-additional .dx-loadindicator-icon-custom {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  transform-origin: 50% 50%;
  animation: 1.5s linear infinite dx-loadindicator-icon-custom-rotate;
}
@keyframes dx-loadindicator-icon-custom-rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.dx-swatch-additional .dx-loadindicator-container > .dx-loadindicator {
  top: 50%;
  left: 50%;
  position: absolute;
  margin-top: -16px;
  margin-left: -16px;
}
.dx-swatch-additional .dx-loadindicator-container > .dx-loadindicator.dx-loadindicator {
  margin-top: -16px;
  margin-left: -16px;
}
.dx-swatch-additional .dx-loadindicator-content {
  width: 100%;
  height: 100%;
  position: relative;
}
.dx-swatch-additional .dx-loadindicator-icon {
  direction: ltr;
  position: relative;
  top: 15%;
  inset-inline-start: 15%;
  width: 70%;
  height: 70%;
}
.dx-swatch-additional .dx-loadindicator-icon .dx-loadindicator-segment {
  position: absolute;
  width: 19%;
  height: 30%;
  left: 44.5%;
  top: 37%;
  opacity: 0;
  background: #d2d2d2;
  border-radius: 10% 10% 50% 50%;
  box-shadow: 0 0 3px rgba(0,0,0,.2);
  animation: 1s linear infinite dx-generic-loadindicator-opacity;
}
@keyframes dx-generic-loadindicator-opacity {
  from {
    opacity: 1;
  }
  to {
    opacity: .55;
  }
}
.dx-swatch-additional .dx-loadindicator-icon .dx-loadindicator-segment0 {
  transform: rotate(0) translate(0,-142%);
  animation-delay: 0s;
}
.dx-swatch-additional .dx-loadindicator-icon .dx-loadindicator-segment1 {
  transform: rotate(45deg) translate(0,-142%);
  animation-delay: -875ms;
}
.dx-swatch-additional .dx-loadindicator-icon .dx-loadindicator-segment2 {
  transform: rotate(90deg) translate(0,-142%);
  animation-delay: -.75s;
}
.dx-swatch-additional .dx-loadindicator-icon .dx-loadindicator-segment3 {
  transform: rotate(135deg) translate(0,-142%);
  animation-delay: -625ms;
}
.dx-swatch-additional .dx-loadindicator-icon .dx-loadindicator-segment4 {
  transform: rotate(180deg) translate(0,-142%);
  animation-delay: -.5s;
}
.dx-swatch-additional .dx-loadindicator-icon .dx-loadindicator-segment5 {
  transform: rotate(225deg) translate(0,-142%);
  animation-delay: -375ms;
}
.dx-swatch-additional .dx-loadindicator-icon .dx-loadindicator-segment6 {
  transform: rotate(270deg) translate(0,-142%);
  animation-delay: -.25s;
}
.dx-swatch-additional .dx-loadindicator-icon .dx-loadindicator-segment7 {
  transform: rotate(315deg) translate(0,-142%);
  animation-delay: -125ms;
}
.dx-swatch-additional .dx-loadindicator-icon .dx-loadindicator-segment10,
.dx-swatch-additional .dx-loadindicator-icon .dx-loadindicator-segment11,
.dx-swatch-additional .dx-loadindicator-icon .dx-loadindicator-segment12,
.dx-swatch-additional .dx-loadindicator-icon .dx-loadindicator-segment13,
.dx-swatch-additional .dx-loadindicator-icon .dx-loadindicator-segment14,
.dx-swatch-additional .dx-loadindicator-icon .dx-loadindicator-segment15,
.dx-swatch-additional .dx-loadindicator-icon .dx-loadindicator-segment8,
.dx-swatch-additional .dx-loadindicator-icon .dx-loadindicator-segment9 {
  display: none;
}
.dx-swatch-additional .dx-treeview-loadindicator-wrapper {
  text-align: center;
}
.dx-swatch-additional .dx-treeview {
  height: 100%;
}
.dx-swatch-additional .dx-treeview .dx-scrollable:focus,
.dx-swatch-additional .dx-treeview :focus {
  outline: 0;
}
.dx-swatch-additional .dx-treeview .dx-empty-message {
  line-height: normal;
}
.dx-swatch-additional .dx-treeview .dx-treeview-node.dx-treeview-root-node {
  -webkit-padding-start: 0;
  padding-inline-start: 0;
}
.dx-swatch-additional .dx-checkbox + .dx-treeview-node-container,
.dx-swatch-additional .dx-treeview-node-container:first-child {
  margin: 0;
  display: block;
}
.dx-swatch-additional .dx-treeview-select-all-item {
  width: 100%;
}
.dx-swatch-additional .dx-treeview-node-container {
  list-style-position: inside;
  padding: 0;
  margin: 0;
  display: none;
  overflow: hidden;
}
.dx-swatch-additional .dx-treeview-node-container.dx-treeview-node-container-opened {
  display: block;
}
.dx-swatch-additional .dx-treeview-node a {
  text-decoration: none;
}
.dx-swatch-additional .dx-treeview-node .dx-checkbox {
  order: 1;
  margin: 0;
}
.dx-swatch-additional .dx-treeview-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dx-swatch-additional .dx-treeview-item .dx-treeview-item-content {
  flex: 1 1 auto;
  order: 2;
}
.dx-swatch-additional .dx-treeview-item .dx-treeview-item-content .dx-icon {
  -webkit-margin-end: 8px;
  margin-inline-end: 8px;
  width: 18px;
  height: 18px;
  background-position: 0 0;
  background-size: 18px 18px;
  padding: 0;
  font-size: 18px;
  text-align: center;
  line-height: 18px;
  vertical-align: bottom;
}
.dx-swatch-additional .dx-treeview-item .dx-treeview-item-content .dx-button-has-icon .dx-icon {
  -webkit-margin-end: 0;
  margin-inline-end: 0;
}
.dx-swatch-additional .dx-treeview-item .dx-item-content .dx-item-url {
  display: flex;
  align-items: center;
}
.dx-swatch-additional .dx-treeview-item.dx-state-disabled {
  opacity: .5;
}
.dx-swatch-additional .dx-treeview-custom-collapse-icon,
.dx-swatch-additional .dx-treeview-custom-expand-icon,
.dx-swatch-additional .dx-treeview-toggle-item-visibility {
  cursor: pointer;
  order: 0;
}
.dx-swatch-additional .dx-treeview-custom-collapse-icon.dx-state-disabled,
.dx-swatch-additional .dx-treeview-custom-expand-icon.dx-state-disabled,
.dx-swatch-additional .dx-treeview-toggle-item-visibility.dx-state-disabled {
  cursor: default;
}
.dx-swatch-additional .dx-rtl .dx-treeview-custom-collapse-icon,
.dx-swatch-additional .dx-rtl .dx-treeview-custom-expand-icon,
.dx-swatch-additional .dx-rtl .dx-treeview-toggle-item-visibility {
  transform: scaleX(-1);
}
.dx-swatch-additional .dx-treeview-custom-collapse-icon,
.dx-swatch-additional .dx-treeview-custom-expand-icon {
  cursor: pointer;
  width: 22px;
  height: 22px;
  background-position: 2px 2px;
  background-size: 18px 18px;
  padding: 2px;
  font-size: 18px;
  text-align: center;
  line-height: 18px;
}
.dx-swatch-additional .dx-treeview-item {
  gap: 8px;
  padding: 5px 10px;
  margin: 0;
}
.dx-swatch-additional .dx-treeview-item.dx-state-hover {
  background-color: rgba(255,255,255,.05);
  border-radius: 0;
  color: #fff;
}
.dx-swatch-additional .dx-state-selected > .dx-treeview-item {
  color: #fff;
  border-radius: 0;
}
.dx-swatch-additional .dx-treeview-node {
  list-style-type: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
  -webkit-padding-start: 15px;
  padding-inline-start: 15px;
}
.dx-swatch-additional .dx-treeview-toggle-item-visibility {
  font: 18px/1 DXIcons;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  color: #fff;
}
.dx-swatch-additional .dx-treeview-toggle-item-visibility::before {
  content: "\f04e";
}
.dx-swatch-additional .dx-treeview-toggle-item-visibility.dx-treeview-toggle-item-visibility-opened {
  font: 18px/1 DXIcons;
}
.dx-swatch-additional .dx-treeview-toggle-item-visibility.dx-treeview-toggle-item-visibility-opened::before {
  content: "\f001";
}
.dx-swatch-additional .dx-treeview-expander-icon-stub {
  display: flex;
  flex-shrink: 0;
  width: 22px;
  height: 22px;
}
.dx-swatch-additional .dx-treeview-item-with-checkbox .dx-checkbox .dx-checkbox-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
}
.dx-swatch-additional .dx-treeview-item-with-checkbox .dx-treeview-item {
  color: #fff;
}
.dx-swatch-additional .dx-treeview-item-without-checkbox.dx-state-selected > .dx-treeview-item {
  color: #fff;
  background-color: rgba(255,255,255,.12);
}
.dx-swatch-additional .dx-treeview-item-without-checkbox.dx-state-focused > .dx-treeview-item {
  background-color: #337ab7;
  color: #2a2a2a;
  border-radius: 0;
}
.dx-swatch-additional .dx-treeview-search {
  margin-bottom: 5px;
}
.dx-swatch-additional .dx-treeview-with-search > .dx-scrollable {
  height: calc(100% - 41px);
}
.dx-swatch-additional .dx-treeview-select-all-item {
  border-bottom: 1px solid #4d4d4d;
  padding: 9px 40px 11px;
}
.dx-swatch-additional .dx-treeview-select-all-item .dx-checkbox-container {
  display: flex;
  align-items: center;
}
.dx-swatch-additional .dx-treeview-select-all-item .dx-checkbox-text {
  -webkit-padding-start: 8px;
  padding-inline-start: 8px;
}
.dx-swatch-additional .dx-treeview-node-loadindicator {
  width: 22px;
  height: 22px;
}
.dx-swatch-additional .dx-treeview-border-visible {
  border: 1px solid #4d4d4d;
}
.dx-swatch-additional .dx-treeview-border-visible .dx-treeview-select-all-item {
  -webkit-padding-start: 20px;
  padding-inline-start: 20px;
}
.dx-swatch-additional .dx-treeview-border-visible .dx-scrollable-content > .dx-treeview-node-container {
  padding: 1px 1px 1px 7px;
}
.dx-swatch-additional .dx-treeview-item-with-checkbox.dx-state-focused > .dx-treeview-item .dx-checkbox .dx-checkbox-icon {
  border: 1px solid #337ab7;
}
.dx-swatch-additional .dx-treeview-item-without-checkbox.dx-state-focused > .dx-treeview-item .dx-treeview-toggle-item-visibility {
  color: unset;
}
.dx-swatch-additional .dx-treeview-item-without-checkbox.dx-state-focused > .dx-treeview-item .dx-treeview-node-loadindicator .dx-loadindicator-icon .dx-loadindicator-segment {
  background: #fff;
}
.dx-swatch-additional .dx-treeview-item-without-checkbox.dx-state-focused > .dx-treeview-item .dx-item-content .dx-item-url {
  color: unset;
}