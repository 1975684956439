@import "../../dx-styles.scss";

.dropped-area {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.dropped-item {
  background-color: #f0f0f0;
  margin-bottom: 5px;
  padding: 5px;
}

.control-item {
  @extend .font-medium-12;
  background-color: #d8ebfc;
  margin: 0px 5px 5px 5px;
  padding: 6px;
  cursor: pointer;
  border: 1px solid $base-accent;
  border-radius: 5px;

}

.widthForLarge {
  width: 45%;
}

.dragControl {
  background-color: transparent !important;
  border: none !important;
  cursor: move;

  .dx-button-content {
    padding: 0px !important;
  }

  i.dx-icon.dx-icon-trash {
    color: $base-danger !important;
  }
}

.removeControl {
  background-color: transparent !important;
  border: none !important;

  .dx-button-content {
    padding: 0px !important;
  }

  i.dx-icon.dx-icon-trash {
    color: $base-danger !important;
  }
}

.heightForExLarge {
 height: 500px;
}

.heightForLarge {
  height: 400px;
 }
 

.componentsSection {
  border: 1px solid $base-accent;
  border-radius: 5px;
}

.componentsTitle {
  @extend .font-semiBold-15;
  background-color: $base-accent;
  color: $base-white;
  border-radius: 4px 4px 0 0;
  text-align: center;
  padding: 5px;

}

.mandatoryCheckbox{
  @extend .font-semiBold-12;
}