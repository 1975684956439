@import '../../../dx-styles.scss';
@import '../../../utils/custom-additional-variables.scss';

.LoginPage-banner-uploader {

    #dropzone-external {
        width: 280px;
        height: 280px;
        border-radius: 4px;
        background-color: $base-picture-bg;
        border-width: 1px;
        border-style: solid;
        padding: 0px;
        display: flex;
        justify-content: center;
    }

    #dropzone-external>* {
        pointer-events: none;
    }

    #dropzone-external.dropzone-active {
        border-style: solid;
    }

    #dropzone-image {
        max-width: 100%;
        max-height: 100%;
    }

    .Login-banner-Preview-Container{
        display: flex;
        vertical-align: middle !important;
        align-items: center;
        justify-content: center;
        max-height: 100%;
        max-width: 100%;
    }
    
    .Event-logo-Preview {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
    }


    #dropzone-text {
        @extend .font-regular-13;


        span {
            font-weight: 100;
            //opacity: 0.5;
        }
    }

    #upload-progress {
        display: flex;
        margin-top: 10px;
    }

    .maxImageSizeSpan {
        font-size: 10px;
        color: $base-danger;
    }

    .maxImageSizeValidation {
        color: $base-danger;
    }


}
.image-Skeleton-LoginBanner {
    width: 280px;
    height: 280px;
}