@import "../../dx-styles.scss";
@import "../../themes/generated/variables.additional.scss";

.side-navigation-menu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 280px !important;

  .menu-container {
    min-height: calc(100vh - 140px);
    
    display: flex;
    flex: 1;
    background-color: $base-accent;
    .dx-treeview {
      // ## Long text positioning
      white-space: nowrap;
      // ##

      // ## Icon width customization
      .dx-treeview-item {
        padding-left: 0;
        padding-right: 0;
        font-size: 15px;
        .dx-icon {
          margin:0px 20px !important;
          display: inline-block;
          vertical-align: middle;
          width: 22px;
          height: 22px;
          background-position: 0 0;
          background-size: 22px 22px;
          padding: 0;
          font-size: 22px;
          text-align: center;
          line-height: 22px;
        }
      
      }
      // ##
      
      // ## Arrow customization
      .dx-treeview-node {
        padding: 0 0 !important;
      }

      .dx-treeview-toggle-item-visibility {
        right: 10px;
        left: auto;
      }

      .dx-rtl .dx-treeview-toggle-item-visibility {
        left: 10px;
        right: auto;
      }
      // ##

      // ## Item levels customization
      .dx-treeview-node {
        &[aria-level='1'] {
          font-weight: bold;
          
        }
  
        &[aria-level='2'] .dx-treeview-item-content {
          font-weight: 600;
          padding: 0 10px 0 50px !important;
          
          .dx-icon {
            width: 28px !important;
            margin: 0 !important;
          }
        }
      }
      // ##
    }

    // ## Selected & Focuced items customization
    .dx-treeview {
      .dx-treeview-node-container {
        .dx-treeview-node {
          &.dx-state-selected:not(.dx-state-focused)> .dx-treeview-item {
            background: transparent;
          }

          &.dx-state-selected > .dx-treeview-item * {
            color:  $base-light-yellow;
          }

          &:not(.dx-state-focused)>.dx-treeview-item.dx-state-hover {
            background-color: rgba(255, 255, 255, 0.099);
          }
        }
      }
    }

    .dx-theme-generic .dx-treeview {
      .dx-treeview-node-container .dx-treeview-node.dx-state-selected.dx-state-focused > .dx-treeview-item * {
        color: inherit;
      }
    }
    // ##
  }
}

.dx-drawer-overlap.pre-init-blink-fix {
  @import "../../utils/patches.scss";
  @include menu-pre-init-patch;
}
