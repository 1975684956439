@import '../../../dx-styles.scss';
@import '../../../utils/custom-additional-variables.scss';

.User_PictureSection {

  #dropzone-external {
      width: 167px;
      height: 167px;
      border-radius: 4px;
      background-color: $base-picture-bg;
      border-width: 1px;
      border-style: solid;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
  }
 
  #dropzone-external>* {
      pointer-events: none;
  }

  #dropzone-external.dropzone-active {
      border-style: solid;
  }

  #dropzone-image {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
      border-radius: 4px !important;
  }

  #dropzone-text {
      @extend .font-regular-13;
      
              
      span {
          font-weight: 100;
          //opacity: 0.5;
      }
  }

  #upload-progress {
      display: flex;
      margin-top: 10px;
  }

  .maxImageSizeSpan{
      font-size: 10px;
      color: $base-danger;
  }
  .maxImageSizeValidation{
      color: $base-danger;
  }


}

.top-profileSection{
    width: 145px;
}
.show_change_password{
    color: $base-accent;
}

.image-hover-section .profile-image{
    opacity: 1 ;
    transition: 0.5s ;
  }
  .image-hover-section:hover .profile-image{
    opacity: 0.3 !important;
  }
  
  .image-hover-section .content-on-image{
    opacity: 0;
    transition: 0.5s;
    z-index: 100;
  
    transform: translate(2%, 1.5%);
    .screen-small & {
        opacity: 1 !important;
      }
      .screen-ex-small & {
        opacity: 1 !important;
      }
      .screen-xx-small & {
        opacity: 1 !important;
      }
      .screen-x-small & {
        opacity: 1 !important;
      }
  }
  
  .image-hover-section:hover .content-on-image{
    opacity: 1 !important;
  }
  
  .image-Skeleton-Candidateprofile{
    height: 150px;
    width: 150px;
 }