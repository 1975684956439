@import '../../utils/custom-additional-variables.scss';
.container {
  .mainContainer-Colour{
    background-color: $base-secondary-accent;
  }

  .design_image {
    width: 450px !important;
    height :450px !important;
  }

  .Logo-image {
    width: 60px;
  }


  .sub-heading {
    color: $base-navy-blue !important;
    font-size: 15.5px;
  }
}

.image_width_set{
  width: 100% !important;
  height: 100% !important;
}